import { createApi } from '@reduxjs/toolkit/query/react';
import {
  CreateResultBodyPrivate,
  GetManyResultsQueryPrivate,
  GetManyResultsResponsePrivate,
  ResultResponsePrivate,
  UpdateResultBodyPrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { testsApi } from '@/services';
import { authGuardQuery } from '@/services/api/queries';
import { createUrl } from '@/utils';

export const resultsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    createResult: build.mutation<ResultResponsePrivate, CreateResultBodyPrivate>({
      invalidatesTags: [{ id: 'List', type: 'Results' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Results),
      }),
    }),
    deleteResult: build.mutation<void, number>({
      invalidatesTags: [{ id: 'List', type: 'Results' }],
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Results, id),
      }),
    }),
    getResultsList: build.query<
      GetManyResultsResponsePrivate,
      RelationsParams & Partial<GetManyResultsQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((testResult) => ({
          id: testResult.id,
          type: 'Results' as const,
        })) || []),
        { id: 'List', type: 'Results' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          order: '+id',
          ...params,
        },
        url: createUrl(ApiPath.Results),
      }),
    }),
    updateResult: build.mutation<ResultResponsePrivate, UpdateResultBodyPrivate & { id: number }>({
      invalidatesTags: (result) => [{ id: result?.id, type: 'Results' }],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(testsApi.util.invalidateTags(['Tests']));
        });
      },
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Results, id),
      }),
    }),
  }),
  reducerPath: 'resultsApi',
  tagTypes: ['Results'],
});

export const {
  useCreateResultMutation,
  useDeleteResultMutation,
  useGetResultsListQuery,
  useUpdateResultMutation,
} = resultsApi;
