import { createSlice } from '@reduxjs/toolkit';

import { configApi } from '@/services';

export const configSlice = createSlice({
  extraReducers: (builder) => {
    builder.addMatcher(
      configApi.endpoints.getConfig.matchFulfilled,
      (state, action) => action.payload,
    );
  },
  initialState: {},
  name: 'config',
  reducers: {},
});

export const configReducer = configSlice.reducer;
