import React, { FC } from 'react';

import styles from './styles.module.scss';

interface Props {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
}

export const DropdownItem: FC<Props> = ({ icon, label, onClick }) => (
  <div className={styles.dropdownItem} onClick={onClick}>
    <div className={styles.icon}>{icon}</div>
    <div className={styles.label}>{label}</div>
  </div>
);
