import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { stateSelector } from './state';

// Не использовать в компонентах, для этого есть ConfigContext
export const configSelector = createDraftSafeSelector(stateSelector, prop<Config>('config'));

export const configApiRootSelector = createDraftSafeSelector(
  configSelector,
  prop<Config['apiUrl']>('apiUrl'),
);
