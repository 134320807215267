import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import queryString from 'query-string';

import { usersApi } from '@/services';

type Value = Record<string, string | string[] | null | number>;

type PathValues = Record<string, Value>;

type FilterAction = PayloadAction<{ path: string; values: Value }>;

export const filterSlice = createSlice({
  extraReducers: (builder) => {
    builder.addMatcher(usersApi.endpoints.logoutUser.matchFulfilled, (state) => {
      Object.keys(state).forEach((key) => {
        delete state[key];
      });
    });
  },
  initialState: {
    [window.location.pathname]: queryString.parse(window.location.search),
  } as PathValues,
  name: 'filter',
  reducers: {
    resetFilters: (state, { payload }: FilterAction) => {
      const { path, values = {} } = payload;

      return {
        ...state,
        [path]: values,
      };
    },
    setFilter: (state, { payload }: FilterAction) => {
      const { path, values } = payload;

      return {
        ...state,
        [path]: {
          ...state[path],
          ...values,
        },
      };
    },
  },
});

export const { resetFilters, setFilter } = filterSlice.actions;

export const filterReducer = filterSlice.reducer;
