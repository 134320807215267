import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { QuestionResponsePrivate, ResultResponsePrivate } from '@tests/types';
import { prop, sort } from 'ramda';

import { questionsAdapter, resultsAdapter } from '@/adapters';
import { answersSelector } from '@/selectors/answers';
import { concatChangesQuestions } from '@/utils';

import { stateSelector } from './state';

export const questionsEntitySelector = questionsAdapter.getSelectors<RootState>(prop('questions'));

export const diffQuestions = function (a: QuestionResponsePrivate, b: QuestionResponsePrivate) {
  return a.sortOrder - b.sortOrder;
};

const getChangedQuestions: (state: any) => QuestionResponsePrivate[] = (state) =>
  sort(
    diffQuestions,
    concatChangesQuestions(state.questions.changes, questionsEntitySelector.selectAll(state)),
  );

export const questionsItemsSelector = createDraftSafeSelector(stateSelector, getChangedQuestions);

export const questionsSelector = createDraftSafeSelector(
  stateSelector,
  prop<RootState['questions']>('questions'),
);

export const hasQuestionsUpdatingSelector = createDraftSafeSelector(
  questionsSelector,
  ({ questionsUpdating }) => questionsUpdating.length > 0,
);

export const countQuestionsSelector = createDraftSafeSelector(
  questionsSelector,
  prop<number>('count'),
);
