import { MAX_LEN_ANSWER_CROSSWORD_TEXT } from '@tests/types';
import { Form, Input, Typography } from 'antd';
import { equals, pick } from 'ramda';
import React, { ChangeEventHandler, useCallback, useEffect, useState } from 'react';

import { FormBlockProps } from '@/containers/Blocks/Wrappers';
import { useDebounce, useGetValidationError, ValidatingBlockProps } from '@/hooks';
import { useGetAnswersListQuery, useUpdateAnswerMutation } from '@/services';

import styles from '../styles.module.scss';

const { TextArea } = Input;

type Props = {
  questionId: number;
};

export const CrosswordAnswer: React.FC<Props> = ({ questionId }) => {
  const [form] = Form.useForm();

  const [answerCount, setAnswerCount] = useState(0);
  const [answerText, setAnswerText] = useState('');
  const [answerId, setAnswerId] = useState(null);

  const [isLoaded, setLoaded] = useState(false);

  const [updateAnswerBlock] = useUpdateAnswerMutation();

  const { getFieldsValue, setFields, submit } = form;
  const [previousFormValues, setPreviousFormValues] = useState({ answerText } || {});

  const { data: { results: answers = [] } = {} } = useGetAnswersListQuery(
    { question: questionId, relations: ['media'] },
    { refetchOnMountOrArgChange: true, skip: !questionId },
  );

  const answerError = useGetValidationError({
    field: 'text',
    targetId: answerId,
  } as ValidatingBlockProps);

  const onAnswerChange = useCallback((text: ChangeEventHandler<HTMLTextAreaElement>) => {
    setAnswerCount(text?.length);
  }, []);

  useEffect(() => {
    if (answers.length > 0) {
      setAnswerCount(answers[0]?.text?.length || 0);
      setAnswerText(answers[0]?.text);
      setAnswerId(answers[0]?.id);
      setLoaded(true);
    }
  }, [answers]);

  const handleFormChangeHandler = useDebounce(() => {
    submit();
  }, 300);

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();

    await updateAnswerBlock({
      ...{ text: formValues?.answerText },
      id: answerId,
    });
    setFields(Object.keys(formValues)?.map((name) => ({ errors: [], name, touched: false })));
  }, [answerId, getFieldsValue, setFields, updateAnswerBlock]);

  const handleValuesChangeHandler: FormBlockProps['onValuesChange'] = useCallback(
    (changedValues: any) => {
      const formValuesToCompare = pick(Object.keys(changedValues))(previousFormValues);

      if (equals(changedValues, formValuesToCompare)) {
        return;
      }

      setFields(Object.keys(changedValues)?.map((name) => ({ errors: [], name })));

      setPreviousFormValues({ ...previousFormValues, ...changedValues });

      onAnswerChange(changedValues?.answerText);
      handleFormChangeHandler.current();
    },
    [handleFormChangeHandler, onAnswerChange, previousFormValues, setFields],
  );

  return (
    isLoaded && (
      <Form
        name={`answer-${answerId}`}
        layout="vertical"
        validateTrigger="onKeyPress"
        form={form}
        initialValues={{ answerText }}
        onValuesChange={handleValuesChangeHandler}
        onFinish={handleFinish}
        key={answerId}
      >
        <Typography.Text className={styles.areaTitle}>Ответ</Typography.Text>
        <Form.Item
          name="answerText"
          validateStatus={answerError && 'error'}
          help={answerError?.message}
        >
          <TextArea
            className={styles.answerInput}
            autoSize
            rows={1}
            bordered
            maxLength={MAX_LEN_ANSWER_CROSSWORD_TEXT}
            placeholder="Напишите ответ"
          />
        </Form.Item>
        {MAX_LEN_ANSWER_CROSSWORD_TEXT && (
          <Typography.Text className={styles.countText}>
            {answerCount || 0}/{MAX_LEN_ANSWER_CROSSWORD_TEXT}
          </Typography.Text>
        )}
      </Form>
    )
  );
};
