import { ExclamationCircleFilled } from '@ant-design/icons';
import { Col } from 'antd';
import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface Props {
  className?: string;
  disabled: boolean;
  hasErrors: boolean;
  label: string;
  name: string;
  onClick?: () => void;
  questionsCount?: number;
  selectedItem?: string | number;
  span?: number;
}

export const MenuItem = ({
  className,
  disabled,
  hasErrors,
  label,
  name,
  onClick,
  questionsCount,
  selectedItem,
  span,
}: Props) => (
  <Col
    span={span}
    onClick={onClick}
    className={classNames(
      styles.item,
      selectedItem === name && styles.selected,
      disabled && styles.disabled,
      hasErrors && styles.hasErrors,
      className,
    )}
  >
    <div>
      {label}
      {name === 'questions' && <span className={styles.questionsCount}>{questionsCount}</span>}
    </div>
    {hasErrors && <ExclamationCircleFilled className={styles.error} />}
  </Col>
);
