import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

interface Props {
  blockTitle?: string;
  children?: any;
  classname?: string;
  count?: number;
  headerChildren?: any;
  headerClassName?: string;
  inverted?: boolean;
  maxCount?: number;
  rootClassName?: string;
}

export const BlockWrapper: React.FC<Props> = (props) => {
  const {
    blockTitle,
    children,
    classname,
    count = 0,
    headerChildren,
    headerClassName,
    inverted = false,
    maxCount,
    rootClassName,
  } = props;

  if (inverted) {
    return (
      <div className={classNames(styles.blockWrapper, styles.inverted, styles[classname])}>
        {blockTitle && (
          <Row
            justify="space-between"
            align="middle"
            className={classNames(styles.blockTitle, styles.inverted, styles[classname])}
          >
            <Typography.Text>{blockTitle}</Typography.Text>
          </Row>
        )}
        <Row>
          <Col span={24}>{children}</Col>
        </Row>
        {maxCount && (
          <Col span={24}>
            <Typography.Text className={classNames(styles.countText, styles.inverted)}>
              {count}/{maxCount}
            </Typography.Text>
          </Col>
        )}
      </div>
    );
  }

  return (
    <div className={classNames(styles.blockWrapper, styles[classname], rootClassName)}>
      <Row
        justify="space-between"
        align="middle"
        className={classNames(styles.blockTitle, styles[classname], headerClassName)}
      >
        <Typography.Text>{blockTitle}</Typography.Text>
        {maxCount && (
          <Typography.Text className={styles.countText}>
            {count}/{maxCount}
          </Typography.Text>
        )}
        {headerChildren && headerChildren}
      </Row>
      <Row className={styles.content}>
        <Col span={24}>{children}</Col>
      </Row>
    </div>
  );
};
