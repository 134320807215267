import { TestValidationError, TestValidationErrorTarget } from '@tests/types';
import { Row } from 'antd';
import React, { SetStateAction, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { EditMenu } from '@/constants';
import { useDisabledByUpdate } from '@/hooks';
import { validationErrorsTestsSelector } from '@/selectors';
import { getValidationErrorsByTarget, getValidationErrorsByTargetAndField } from '@/utils';

import { MenuItem } from '../../atoms';
import styles from './styles.module.scss';

type Props = {
  activeMenu: string | number;
  items: { key: string; label: string }[];
  questionsCount: number;
  setActiveEditContent: React.Dispatch<SetStateAction<string | number>>;
};

const getValidationErrorsMatched = (menuKey: string, validationErrors: TestValidationError[]) => {
  switch (menuKey) {
    case EditMenu.generalSettings: {
      return getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Test);
    }
    case EditMenu.results: {
      return getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Result);
    }
    case EditMenu.questions: {
      return [
        ...(getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Question) ||
          []),
        ...(getValidationErrorsByTarget(validationErrors, TestValidationErrorTarget.Answer) || []),
      ];
    }
    case EditMenu.iframeCode: {
      return getValidationErrorsByTargetAndField(
        validationErrors,
        TestValidationErrorTarget.Test,
        'crossword',
      );
    }
    default: {
      return null;
    }
  }
};

export const LeftSideMenu: React.FC<Props> = ({
  items = [],
  setActiveEditContent,
  activeMenu,
  questionsCount,
}) => {
  const [selectedItem, setSelectedItem] = useState(activeMenu || null);

  const disabled = useDisabledByUpdate();

  const validationErrors = useSelector(validationErrorsTestsSelector);

  useEffect(() => {
    setSelectedItem(activeMenu);
    const testNumber = window.location.href.split('/').pop();
    const activeTestMenu: Record<string, string> = {};
    activeTestMenu[testNumber] = String(activeMenu);
    sessionStorage.setItem('activeEditContent', JSON.stringify(activeTestMenu));
  }, [activeMenu]);

  if (!items) {
    return <div>Ошибка загрузки пунктов меню!</div>;
  }

  return (
    <Row className={styles.menu}>
      {items.map(({ key, label }) => {
        const hasErrors = getValidationErrorsMatched(key, validationErrors)?.length > 0;

        return (
          <MenuItem
            key={key}
            name={key}
            label={label}
            span={24}
            selectedItem={selectedItem}
            disabled={disabled}
            hasErrors={hasErrors}
            questionsCount={questionsCount}
            onClick={() => {
              setSelectedItem(key);
              setActiveEditContent(key);
            }}
          />
        );
      })}
    </Row>
  );
};
