import { AnswerType, TestStatus, TestType } from '@tests/types';
import { always, cond, equals, T } from 'ramda';

export const isDraft = equals(TestStatus.Draft);
export const isPublished = equals(TestStatus.Published);
export const isUnpublished = equals(TestStatus.Unpublished);

export const isTest = equals(TestType.Test);
export const isExtendedTest = equals(TestType.Extended);
export const isPoll = equals(TestType.Poll);
export const isQuiz = equals(TestType.Quiz);
export const isCrossword = equals(TestType.Crossword);

export const isTextAnswer = equals(AnswerType.Text);
export const isImageAnswer = equals(AnswerType.Image);

export const getTypeTitle = cond<[string], Nullable<string>>([
  [equals(TestType.Test), always('Тест')],
  [equals(TestType.Quiz), always('Квиз')],
  [equals(TestType.Extended), always('Расширенный тест')],
  [equals(TestType.Poll), always('Голосование')],
  [equals(TestType.Crossword), always('Кроссворд')],
  [T, always(null)],
]);
