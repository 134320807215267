export enum WidthCalculationMethod {
  BodyOffset = 'bodyOffset',
  BodyScroll = 'bodyScroll',
  DocumentElementOffset = 'documentElementOffset',
  DocumentElementScroll = 'documentElementScroll',
  Max = 'max',
  Min = 'min',
  Scroll = 'scroll',
  RightMostElement = 'rightMostElement',
  TaggedElement = 'taggedElement',
}
