import { createApi } from '@reduxjs/toolkit/query/react';
import { GetManyThemesQueryPrivate, GetManyThemesResponsePrivate } from '@tests/types';

import { ApiPath } from '@/constants';
import { createUrl } from '@/utils';

import { authGuardQuery } from './queries';

export const themesApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getThemesList: build.query<
      GetManyThemesResponsePrivate,
      RelationsParams & Partial<GetManyThemesQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((theme) => ({
          id: theme.id,
          type: 'Themes' as const,
        })) || []),
        { id: 'List', type: 'Themes' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Themes),
      }),
    }),
  }),
  reducerPath: 'themesApi',
  tagTypes: ['Themes'],
});

export const { useGetThemesListQuery } = themesApi;
