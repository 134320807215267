import { createApi } from '@reduxjs/toolkit/query/react';
import type { Employee, GetTokensResponsePrivate } from '@tests/types';

import { ApiPath, Provider } from '@/constants';
import { createQuery, createUrl } from '@/utils';

import { authGuardQuery } from './queries';

export const usersApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getCurrentUser: build.query<Employee, void>({
      query: createQuery(ApiPath.Auth, ApiPath.Me),
    }),
    loginUser: build.mutation<GetTokensResponsePrivate, unknown>({
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Login),
      }),
    }),
    logoutUser: build.mutation<void, void>({
      query: () => ({
        method: 'POST',
        url: createUrl(ApiPath.Auth, ApiPath.Logout),
      }),
    }),
    oAuthCallback: build.mutation<
      { accessToken: string },
      { code: string; provider: Provider; redirectUri: string }
    >({
      query: ({ provider, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
          redirect_uri: params?.redirectUri,
        },
        url: createUrl(ApiPath.Auth, provider, ApiPath.Callback),
      }),
    }),
  }),
  reducerPath: 'usersApi',
});

export const {
  useGetCurrentUserQuery,
  useLoginUserMutation,
  useLogoutUserMutation,
  useOAuthCallbackMutation,
} = usersApi;
