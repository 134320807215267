import { LeftOutlined } from '@ant-design/icons';
import { TestResponsePrivate, TestType } from '@tests/types';
import { Modal, Tooltip } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CloseIcon, Menu, Trash } from '@/assets';
import { Badge, ConfirmModal, DateChanged, Status, Visible } from '@/components';
import { RoutesPath } from '@/constants';
import { useDisabledByUpdate } from '@/hooks';
import {
  useDeleteTestMutation,
  usePublishTestMutation,
  useUnpublishTestMutation,
} from '@/services';
import { isDraft, isPublished, isUnpublished } from '@/utils';

import { Button, message } from '../../atoms';
import { validationErrorsTestsSelector } from '../../selectors';
import { ButtonType } from '../../types';
import styles from './styles.module.scss';

const { confirm } = Modal;

type Props = {
  isMenuShowed?: boolean;
  onMenuClick?: () => void;
  test: TestResponsePrivate;
};

const getTypeTitle = cond<[string], Nullable<string>>([
  [equals(TestType.Test), always('Тест')],
  [equals(TestType.Quiz), always('Квиз')],
  [equals(TestType.Extended), always('Расширенный тест')],
  [equals(TestType.Poll), always('Голосование')],
  [equals(TestType.Crossword), always('Кроссворд')],
  [T, always(null)],
]);

export const TestEditPageHeader: React.FC<Props> = ({ isMenuShowed, onMenuClick, test }) => {
  const { createdAt, hasUnpublishedChanges, id, status, type, updatedAt } = test;
  const navigate = useNavigate();

  const validationErrors = useSelector(validationErrorsTestsSelector);

  const [deleteTest] = useDeleteTestMutation();

  const [publishTest] = usePublishTestMutation();
  const [unpublishTest] = useUnpublishTestMutation();

  const handleDeleteClick = useCallback(async () => {
    ConfirmModal({
      onOk: async () => {
        await deleteTest(id);
        navigate(RoutesPath.Tests);
        message({
          description: 'Тест удален',
          type: 'success',
        });
      },

      text: 'Отменить это действие будет невозможно.',
      title: 'Удалить материал?',
    });
  }, [deleteTest, id]);

  const handlePublishTestClick = useCallback(async () => {
    const response = await publishTest({ id });

    if (
      ('data' in response && response.data?.validationErrors?.length > 0) ||
      'error' in response
    ) {
      message({
        description: 'Заполните обязательные поля',
        title: 'Не удалось применить изменения',
        type: 'error',
      });
    }
  }, [id, validationErrors]);

  const handleUnpublishTestClick = useCallback(() => {
    ConfirmModal({
      cancelText: 'Нет',
      okText: 'Да',
      onOk: async () => {
        await unpublishTest({ id });
      },
      title: 'Снять с публикации?',
    });
  }, [id]);

  const handleGoBackButton = () => {
    if (document.referrer && document.referrer !== window.location.href) {
      window.history.back();
    } else {
      navigate('/tests');
    }
  };

  const disabled = useDisabledByUpdate();

  return (
    <div className={styles.header}>
      <div className={styles.headerMobile}>
        <button className={styles.goBackButton} onClick={handleGoBackButton} type="button">
          <LeftOutlined /> Назад
        </button>
        <button className={styles.menuButton} onClick={onMenuClick} type="button">
          {isMenuShowed ? <CloseIcon /> : <Menu />}
        </button>
      </div>
      <div className={styles.headerRow}>
        <div className={styles.headerCol}>
          <div className={styles.headerTop}>
            <button className={styles.goBackButton} onClick={handleGoBackButton} type="button">
              <LeftOutlined /> Назад
            </button>
            <div className={styles.headerTopInfo}>
              <Badge className={type}>{getTypeTitle(type)}</Badge>
              <Status status={status} />
            </div>
            <Tooltip
              title={
                <div className={classNames(styles.datesRow, styles.tooltipDatesRow)}>
                  <div className={styles.dates}>
                    <DateChanged date={createdAt} type="created" nowrap />
                  </div>
                  <div className={styles.separator} />
                  <div className={styles.dates}>
                    <DateChanged date={updatedAt} type="updated" nowrap />
                  </div>
                </div>
              }
            >
              <div className={styles.datesRow}>
                <div className={styles.dates}>
                  <DateChanged date={createdAt} type="created" nowrap />
                </div>
                <div className={styles.separator} />
                <div className={styles.dates}>
                  <DateChanged date={updatedAt} type="updated" nowrap />
                </div>
              </div>
            </Tooltip>
          </div>
        </div>
        <div className={classNames(styles.headerCol, styles.headerColButtons)}>
          <div className={styles.mainButtons}>
            <Button
              type={ButtonType.Secondary}
              className={classNames(styles.defaultButton, styles.deleteButton)}
              onClick={handleDeleteClick}
            >
              <Trash className={styles.trashIcon} />
            </Button>
            <Button
              type={ButtonType.Secondary}
              className={classNames(styles.defaultButton, !isPublished(status) && styles.disabled)}
              disabled={!isPublished(status)}
              onClick={handleUnpublishTestClick}
            >
              Снять с публикации
            </Button>
          </div>
          <Visible isVisible={isUnpublished(status) || isDraft(status)}>
            <Button
              type={ButtonType.Primary}
              className={classNames(styles.primaryButton)}
              disabled={disabled}
              onClick={handlePublishTestClick}
            >
              Опубликовать
            </Button>
          </Visible>
          <Visible isVisible={isPublished(status)}>
            <Button
              type={ButtonType.Primary}
              className={classNames(styles.primaryButton)}
              disabled={disabled || !hasUnpublishedChanges}
              onClick={handlePublishTestClick}
            >
              Применить
            </Button>
          </Visible>
        </div>
      </div>
    </div>
  );
};
