import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { formatDate } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  date?: string;
  nowrap?: boolean;
  type: 'created' | 'updated' | 'saved' | 'deleted';
};

const getDateChangedTypeTitle = cond<[string], Nullable<string>>([
  [equals('created'), always('Создан')],
  [equals('updated'), always('Изменен')],
  [equals('saved'), always('Сохранен')],
  [equals('deleted'), always('Удалён')],
  [T, always(null)],
]);

export const DateChanged: React.FC<Props> = ({ date, nowrap = false, type }) =>
  date && (
    <div className={classNames(styles.dateChanged, nowrap && styles.nowrap)}>
      <div className={styles.title}>{getDateChangedTypeTitle(type)}:&nbsp;</div>
      <div className={styles.date}>{formatDate(date, {})}</div>
    </div>
  );
