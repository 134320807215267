import { UploadFileStatus } from 'antd/es/upload/interface';
import React from 'react';

import { ButtonType } from '@/types';

import { Button } from '../../Button/Button';
import { DropZonePreviewIcon } from '../../DropZonePreviewIcon';
import styles from './styles.module.scss';

interface Props {
  accept?: string;
  progress?: number;
  title?: string;
  uploadState?: UploadFileStatus | 'converting' | null;
}

export const DropZonePreviewRegular = ({
  accept,
  progress,
  title = 'Добавьте изображение',
  uploadState,
}: Props) => (
  <div className={styles.preview}>
    <DropZonePreviewIcon
      progress={progress}
      uploadState={uploadState}
      iconClassName={styles.icon}
    />
    <div className={styles.title}>{title}</div>
    <Button className={styles.button} type={ButtonType.Secondary}>
      Добавить файл
    </Button>
    {accept && (
      <div className={styles.accept}>
        допустимый формат: <span>{accept}</span>
      </div>
    )}
  </div>
);
