import { Progress, Spin } from 'antd';
import { UploadFileStatus } from 'antd/es/upload/interface';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { DropZoneImageIcon } from '@/assets';

import styles from './styles.module.scss';

interface Props {
  iconClassName?: string;
  progress?: number;
  uploadState?: UploadFileStatus | 'converting' | null;
}

const getLoaderText = cond([
  [equals('processing'), always('Обработка')],
  [equals('converting'), always('Конвертация')],
  [equals('uploading'), always('Загрузка')],
  [T, always('')],
]);

export const DropZonePreviewIcon = ({ iconClassName, progress, uploadState }: Props) => {
  if (uploadState) {
    return (
      <div className={styles.loader}>
        {uploadState === 'converting' ? (
          <Spin className={styles.spinner} size="large" />
        ) : (
          <Progress percent={progress} type="circle" size={50} />
        )}

        <div className={styles.loaderText}>{getLoaderText(uploadState)}</div>
      </div>
    );
  }

  return <DropZoneImageIcon className={iconClassName} />;
};
