import { combineReducers } from '@reduxjs/toolkit';

import {
  answersApi,
  configApi,
  employeesApi,
  filesApi,
  permissionsApi,
  projectsApi,
  questionsApi,
  rolesApi,
  testsApi,
  themesApi,
  usersApi,
} from '@/services';
import { resultsApi } from '@/services/api/results';
import {
  answersReducer,
  backgroundsReducer,
  configReducer,
  employeesReducer,
  filterReducer,
  permissionsReducer,
  projectsReducer,
  questionsReducer,
  resultsReducer,
  rolesReducer,
  testsReducer,
  themesReducer,
  userReducer,
} from '@/slices';

export default combineReducers({
  answers: answersReducer,
  [answersApi.reducerPath]: answersApi.reducer,
  backgrounds: backgroundsReducer,
  [configApi.reducerPath]: configApi.reducer,
  config: configReducer,
  employees: employeesReducer,
  filter: filterReducer,
  permissions: permissionsReducer,
  [testsApi.reducerPath]: testsApi.reducer,
  projects: projectsReducer,
  questions: questionsReducer,
  results: resultsReducer,
  roles: rolesReducer,
  [themesApi.reducerPath]: themesApi.reducer,
  tests: testsReducer,
  [questionsApi.reducerPath]: questionsApi.reducer,
  [projectsApi.reducerPath]: projectsApi.reducer,
  [employeesApi.reducerPath]: employeesApi.reducer,
  themes: themesReducer,
  [resultsApi.reducerPath]: resultsApi.reducer,
  [rolesApi.reducerPath]: rolesApi.reducer,
  [permissionsApi.reducerPath]: permissionsApi.reducer,
  user: userReducer,
  [usersApi.reducerPath]: usersApi.reducer,
  [filesApi.reducerPath]: filesApi.reducer,
});
