import { AnswerResponsePrivate } from '@tests/types';
import React from 'react';

import { TextAreaBlock } from '@/components';
import { DropType } from '@/constants';
import { useUpdateAnswerMutation } from '@/services';

import { ImageBlock } from '../../ImageBlock';
import styles from './styles.module.scss';

type Props = {
  answer: AnswerResponsePrivate;
  testId: number;
};

export const AnswerResultBlock: React.FC<Props> = ({ answer, testId }) => {
  const { id, resultMedia, resultMediaCaption, resultMediaId, resultSubtitle, resultTitle } =
    answer;

  const [updateBlock] = useUpdateAnswerMutation();

  return (
    <div className={styles.container}>
      <TextAreaBlock
        text={resultTitle}
        id={id}
        formItemName="resultTitle"
        updateBlock={updateBlock}
        title="Заголовок"
        inverted
        placeholder="Напишите заголовок"
      />
      <TextAreaBlock
        text={resultSubtitle}
        id={id}
        formItemName="resultSubtitle"
        updateBlock={updateBlock}
        title="Подзаголовок"
        inverted
        maxLength={270}
        placeholder="Напишите подзаголовок"
      />
      <ImageBlock
        id={id}
        testId={testId}
        updateBlock={updateBlock}
        backgroundImageId={resultMediaId}
        backgroundImage={resultMedia}
        caption={resultMediaCaption}
        mediaCaptionMaxLength={100}
        title="Медиа к результату"
        withCaption
        rootClassName={styles.image}
        dropType={DropType.All}
        backgroundFormName="resultMediaId"
        captionFormName="resultMediaCaption"
      />
    </div>
  );
};
