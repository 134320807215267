import {
  getResultMediaCaptionLengthByTestType,
  ResultResponsePrivate,
  TestType,
} from '@tests/types';
import { Button, Col, Row } from 'antd';
import { dec } from 'ramda';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DropType } from '@/constants';
// eslint-disable-next-line import/no-cycle
import { ImageBlock, RangeBlock } from '@/containers/Blocks';
import { resultsEntitySelector } from '@/selectors';
import { useUpdateResultMutation } from '@/services';
import { isCrossword, isExtendedTest } from '@/utils';

import { TextAreaBlock } from '../../../components';
import styles from './styles.module.scss';

type Props = {
  index: number;
  onOpenModalAnswersResult?: (id: number) => void;
  result: ResultResponsePrivate;
  testId: number;
  testType: TestType;
};

export const Result: React.FC<Props> = ({
  index,
  onOpenModalAnswersResult,
  result,
  testId,
  testType,
}) => {
  const { id, media, mediaCaption, mediaId, pointsMax, pointsMin, subtitle, title } = result;

  const mediaCaptionMaxLength = getResultMediaCaptionLengthByTestType(testType);

  const [previousResult, setPreviousResult] = useState(null);

  const [updateResultBlock] = useUpdateResultMutation();

  const results = useSelector(resultsEntitySelector.selectAll);

  useEffect(() => {
    if (index > 0) {
      setPreviousResult(results[dec(index)]);
    }
  }, [index, results]);

  return (
    <Row gutter={[0, 20]}>
      {!(isCrossword(testType) || isExtendedTest(testType)) && (
        <RangeBlock
          id={id}
          updateResultBlock={updateResultBlock}
          pointsMax={pointsMax}
          pointsMin={pointsMin}
          resultIndex={index}
          previousResultMax={previousResult?.pointsMax}
          testType={testType}
        />
      )}
      {isExtendedTest(testType) && (
        <Button className={styles.button} onClick={() => onOpenModalAnswersResult(result.id)}>
          Привязанные ответы
        </Button>
      )}
      <Col span={24}>
        <TextAreaBlock
          text={title}
          id={id}
          formItemName="title"
          updateBlock={updateResultBlock}
          title="Заголовок"
          placeholder="Напишите заголовок"
        />
      </Col>
      <Col span={24}>
        <TextAreaBlock
          text={subtitle}
          id={id}
          formItemName="subtitle"
          updateBlock={updateResultBlock}
          title="Подзаголовок"
          maxLength={350}
          placeholder="Напишите подзаголовок"
        />
      </Col>
      <Col span={24}>
        <ImageBlock
          id={id}
          testId={testId}
          updateBlock={updateResultBlock}
          backgroundImageId={mediaId}
          backgroundImage={media}
          mediaCaptionMaxLength={mediaCaptionMaxLength}
          testType={testType}
          title="Медиа к результату"
          withCaption
          caption={mediaCaption}
          dropType={DropType.All}
          backgroundFormName="mediaId"
        />
      </Col>
    </Row>
  );
};
