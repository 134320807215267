import { FileResponsePrivate } from '@tests/types';
import classNames from 'classnames';
import React, { FC } from 'react';
import ReactPlayer from 'react-player';

import styles from './styles.module.scss';

interface Props {
  file: FileResponsePrivate;
  rootClassName?: string;
}

export const DropZoneFilePreview: FC<Props> = ({ file, rootClassName }) => (
  <div className={classNames(styles.previewWrapper, rootClassName)}>
    {file.type === 'image' && <img alt="" className={styles.previewImage} src={file.url} />}
    {file.type === 'video' && <ReactPlayer url={file.url} controls width="100%" height="100%" />}
  </div>
);
