export enum HeightCalculationMethod {
  BodyOffset = 'bodyOffset',
  BodyScroll = 'bodyScroll',
  DocumentElementOffset = 'documentElementOffset',
  DocumentElementScroll = 'documentElementScroll',
  Max = 'max',
  Min = 'min',
  Grow = 'grow',
  LowestElement = 'lowestElement',
  TaggedElement = 'taggedElement',
}
