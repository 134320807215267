import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetManyProjectsQueryPrivate,
  GetManyProjectsResponsePrivate,
  ProjectResponsePrivate,
  UpdateProjectBodyPrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { createUrl } from '@/utils';

import { authGuardQuery } from './queries';

export const projectsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getProjectById: build.query<ProjectResponsePrivate, RelationsParams & { id: number }>({
      providesTags: (test) => [{ id: test?.id, type: 'Projects' }],
      query: ({ id, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Projects, id),
      }),
    }),
    getProjectsList: build.query<
      GetManyProjectsResponsePrivate,
      RelationsParams & Partial<GetManyProjectsQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((project) => ({
          id: project.id,
          type: 'Projects' as const,
        })) || []),
        { id: 'List', type: 'Projects' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Projects),
      }),
    }),
    updateProjectById: build.mutation<
      ProjectResponsePrivate,
      UpdateProjectBodyPrivate & { id: number }
    >({
      invalidatesTags: (result) => [{ id: result?.id, type: 'Projects' }],
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Projects, id),
      }),
    }),
  }),
  reducerPath: 'projectsApi',
  tagTypes: ['Projects'],
});

export const { useGetProjectByIdQuery, useGetProjectsListQuery, useUpdateProjectByIdMutation } =
  projectsApi;
