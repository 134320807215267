import { Permission } from '@tests/types';
import { always, cond, equals, T } from 'ramda';

export const isAdmin = cond([
  [equals('Админ'), always(true)],
  [equals('Cупер админ'), always(true)],
  [T, always(false)],
]);

export const hasPermission = (actionType: string, permissions: Permission[]) => {
  const [action, target] = actionType.split('.');

  return permissions?.some((p) => p.action === action && p.resource === target);
};
