import { CheckOutlined } from '@ant-design/icons';
import { TestResponsePrivate, TestStatus } from '@tests/types';
import { Button } from 'antd';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { generatePath, Link } from 'react-router-dom';

import { Attachments, Link as LinkIcon, Stats, TestoLogo } from '@/assets';
import { message } from '@/atoms';
import { Badge, DateChanged, Status } from '@/components';
import { RoutesPath } from '@/constants';
import { getTypeTitle } from '@/utils';

import styles from './styles.module.scss';

interface Props {
  highlightedIds: number[];
  isHighlightActive: boolean;
  onHandleHighlight: (ids: number[]) => void;
  showDeleted: boolean;
  test: TestResponsePrivate;
}

export const TestCardNew: React.FC<Props> = (props) => {
  const {
    test: {
      title,
      type,
      questionsCount,
      createdAt,
      updatedAt,
      deletedAt,
      projects = [],
      status,
      id,
    },
    isHighlightActive,
    highlightedIds,
    showDeleted,
    onHandleHighlight,
  } = props;

  const handleCopyTestLinkClick = () => {
    navigator.clipboard
      .writeText(
        generatePath(`${window.location.origin}${RoutesPath.TestEdit}`, {
          idOrAlias: id.toString(10),
        }),
      )
      .then(() =>
        message({
          description: 'Ссылка на интерактив скопирована',
          type: 'success',
        }),
      );
  };

  const isHighlighted = useMemo(
    () => isHighlightActive && highlightedIds.includes(id),
    [isHighlightActive, highlightedIds, id],
  );

  const canBeHighlighted = useMemo(
    () =>
      (isHighlightActive && !showDeleted && status !== TestStatus.Published) ||
      (isHighlightActive && showDeleted),
    [isHighlightActive, showDeleted, status],
  );

  return (
    <div
      className={classNames(
        styles.card,
        isHighlighted && styles.highlightedCard,
        isHighlightActive && canBeHighlighted && styles.hover,
      )}
      // eslint-disable-next-line consistent-return
      onMouseDown={(e) => {
        if (e.button === 1) {
          e.preventDefault();
          e.stopPropagation();

          return false;
        }
      }}
      onMouseUp={() => {
        if (canBeHighlighted) {
          if (isHighlighted) {
            onHandleHighlight(highlightedIds.filter((hlId) => hlId !== id));
          } else {
            onHandleHighlight([...highlightedIds, id]);
          }
        }
      }}
    >
      <div className={styles.top}>
        <div className={styles.topInfo}>
          <Badge className={type}>{getTypeTitle(type)}</Badge>
          <Status status={status} />
        </div>
        <div className={styles.topActions}>
          <Attachments />
          {questionsCount}
        </div>
      </div>
      <div className={styles.title}>{title}</div>
      <div className={styles.dates}>
        <DateChanged date={createdAt} type="created" />
        {showDeleted ? (
          <DateChanged date={deletedAt} type="deleted" />
        ) : (
          <DateChanged date={updatedAt} type="updated" />
        )}
      </div>
      <div className={styles.divider} />
      <div className={styles.projectsWrapper}>
        <div className={styles.projects}>
          {projects.map((project) => (
            <Badge className="project" key={project.id}>
              {project?.logo?.url ? (
                <img height={12} src={project?.logo?.url} alt={project.alias} />
              ) : (
                <TestoLogo style={{ height: '12px' }} />
              )}
            </Badge>
          ))}
        </div>
      </div>
      <div className={styles.buttons}>
        {isHighlightActive ? (
          <Button
            className={classNames(styles.button, canBeHighlighted && styles.disabled)}
            disabled={!canBeHighlighted}
          >
            {isHighlighted ? <CheckOutlined /> : 'Выбрать'}
          </Button>
        ) : (
          <>
            {showDeleted ? (
              <Button className={classNames(styles.button, styles.disabled)} disabled>
                Редактировать
              </Button>
            ) : (
              <Link to={`/test/${id}`} className={styles.buttonLink}>
                <Button className={classNames(styles.button)}>Редактировать</Button>
              </Link>
            )}
            <Link
              to={`/test/stats/${id}`}
              className={classNames(styles.buttonLink, styles.buttonSmall)}
            >
              <Button className={classNames(styles.button, styles.buttonSmall)}>
                <Stats />
              </Button>
            </Link>
            <Button
              className={classNames(styles.button, styles.buttonSmall)}
              onClick={handleCopyTestLinkClick}
            >
              <LinkIcon />
            </Button>
          </>
        )}
      </div>
    </div>
  );
};
