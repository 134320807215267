import { AnswerResponsePrivate, AnswerType, TestType, TestValidationError } from '@tests/types';
import { Col, Form, Input, Row, Typography } from 'antd';
import { useWatch } from 'antd/es/form/Form';
import classNames from 'classnames';
import { inc, isNotNil, omit } from 'ramda';
import React, { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';

import { Delete } from '@/assets';
import { DropZoneNew } from '@/atoms';
import { TagSelect } from '@/components';
import { DropType, DropZoneType } from '@/constants';
import { BlockWrapper } from '@/containers/Blocks/Wrappers';
import { useDebounce, useGetValidationError } from '@/hooks';
import { useDeleteAnswerMutation, useUpdateAnswerMutation } from '@/services';
import { isImageAnswer, isQuiz } from '@/utils';

import styles from './styles.module.scss';

const { TextArea } = Input;

type Props = {
  answer: AnswerResponsePrivate;
  answerType: AnswerType;
  canDelete: boolean;
  currentCorrectAnswer: AnswerResponsePrivate | null;
  index: number;
  maxLength?: number;
  questionAnswersError: TestValidationError;

  results: { label: string; tag: ReactNode; value: string | number }[];
  testType: TestType;
};

export const ExtendedAnswerBlock: React.FC<Props> = ({
  answer,
  answerType,
  canDelete,
  currentCorrectAnswer,
  index,
  maxLength = 100,
  results,

  testType,
}) => {
  const [form] = Form.useForm();

  const { getFieldsValue, resetFields, submit } = form;

  const { id, media, mediaCaption, text } = answer;

  const initialValues = useMemo(
    () => ({
      ...omit(['results'], answer),
      results: {
        '+1': { points: 1, resultId: answer.results.find((item) => item.points === 1)?.resultId },
        '+2': { points: 2, resultId: answer.results.find((item) => item.points === 2)?.resultId },
      },
    }),
    [answer],
  );

  const firstResultValue = useWatch(['results', '+1', 'resultId'], form);
  const secondResultValue = useWatch(['results', '+2', 'resultId'], form);

  const [count, setCount] = useState(text?.length || 0);
  const [captionCount, setCaptionCount] = useState(mediaCaption?.length || 0);

  useEffect(() => {
    resetFields();
  }, [initialValues.isCorrect, resetFields]);

  const isImageTypeAnswer = useMemo(() => isImageAnswer(answerType), [answerType]);

  const [updateBlock] = useUpdateAnswerMutation();
  const [deleteBlock] = useDeleteAnswerMutation();

  const answerError = useGetValidationError({ field: 'text', targetId: id });
  const mediaError = useGetValidationError({ field: 'mediaId', targetId: id });

  const handleDeleteClick = useCallback(() => {
    deleteBlock(id);
  }, [deleteBlock, id]);

  const handleFormChangeHandler = useDebounce(() => {
    submit();
  }, 300);

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();
    await updateBlock({
      ...formValues,
      id,
      results: [
        formValues.results['+1'].resultId && formValues.results['+1'],
        formValues.results['+2'].resultId && formValues.results['+2'],
      ].filter((item) => isNotNil(item)),
    });

    if (
      currentCorrectAnswer?.id &&
      isQuiz(testType) &&
      formValues.isCorrect &&
      formValues.isCorrect !== initialValues.isCorrect
    ) {
      await updateBlock({
        id: currentCorrectAnswer?.id,
        isCorrect: false,
      });
    }
  }, [
    getFieldsValue,
    updateBlock,
    id,
    currentCorrectAnswer?.id,
    testType,
    initialValues.isCorrect,
  ]);

  const handleValuesChangeHandler = useCallback(() => {
    handleFormChangeHandler.current();
  }, [handleFormChangeHandler]);

  const onValuesChange = useCallback((event: any) => {
    setCount(event.currentTarget.value.length);
  }, []);

  const onCaptionValuesChange = useCallback((event: any) => {
    setCaptionCount(event.currentTarget.value.length);
  }, []);

  const blockHeaderFieldsAndButtons = useCallback(
    (className = '') => (
      <Row className={className}>
        <Col
          onClick={handleDeleteClick}
          className={classNames(styles.delete, !canDelete && styles.disabled)}
        >
          <Delete />
        </Col>
      </Row>
    ),
    [handleDeleteClick, canDelete],
  );

  return (
    <div className={styles.wrapper}>
      <Form
        form={form}
        initialValues={initialValues}
        onFinish={handleFinish}
        rootClassName={styles.form}
        onValuesChange={handleValuesChangeHandler}
      >
        <div className={styles.container}>
          <BlockWrapper
            count={count}
            blockTitle={`Ответ №${inc(index)}`}
            headerChildren={blockHeaderFieldsAndButtons(styles.topCol)}
          >
            <Row gutter={[15, 15]}>
              <Col span={24}>
                <Form.Item
                  name="text"
                  validateStatus={answerError && 'error'}
                  help={answerError?.message}
                >
                  <TextArea
                    className={classNames(styles.input)}
                    autoSize
                    rows={1}
                    variant="borderless"
                    placeholder="Текст ответа"
                    onChange={onValuesChange}
                    maxLength={maxLength}
                  />
                </Form.Item>
              </Col>
              <Col span={24}>
                <Row justify="end">
                  <Col>
                    <Typography.Text
                      className={classNames(
                        styles.countText,
                        isQuiz(testType) && styles.quizCaption,
                      )}
                    >
                      {count}/{maxLength}
                    </Typography.Text>
                  </Col>
                </Row>
              </Col>
              {isImageTypeAnswer && (
                <Col span={24}>
                  <Row>
                    <Col span={24} className={classNames(styles.delimiter)} />
                  </Row>
                </Col>
              )}
              <Col span={24}>
                {isImageTypeAnswer && (
                  <div className={styles.imageAnswerRow}>
                    <Form.Item
                      name="mediaId"
                      getValueFromEvent={(value) => value?.id ?? null}
                      getValueProps={() => ({
                        value: media,
                      })}
                      noStyle
                    >
                      <DropZoneNew
                        rootClassName={styles.dropZoneRoot}
                        className={styles.dropZone}
                        type={DropZoneType.Compact}
                        dropType={DropType.All}
                        validErrorMessage={mediaError?.message}
                      />
                    </Form.Item>
                    <Row className={classNames(styles.mediaCaptionWrapper)}>
                      <Col span={24}>
                        <Form.Item name="mediaCaption" style={{ margin: 0 }}>
                          <TextArea
                            className={styles.input}
                            autoSize={{ minRows: 3 }}
                            bordered={false}
                            placeholder="Подпись к изображению"
                            onChange={onCaptionValuesChange}
                            maxLength={maxLength}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Row justify="end">
                          <Col>
                            <Typography.Text
                              className={classNames(
                                styles.countText,
                                isQuiz(testType) && styles.quizCaption,
                              )}
                            >
                              {captionCount}/100
                            </Typography.Text>
                          </Col>
                        </Row>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
          </BlockWrapper>
          <div className={styles.separator} />
          <BlockWrapper
            count={count}
            headerClassName={styles.bottomCol}
            rootClassName={styles.bottomBlockWrapper}
            blockTitle="Привязать к результатам"
            headerChildren={blockHeaderFieldsAndButtons(styles.bottomCol)}
          >
            <Form.Item name={['results', '+2', 'points']} noStyle initialValue={2} />
            <Form.Item name={['results', '+1', 'points']} noStyle initialValue={1} />
            <div className={styles.selects}>
              <Form.Item name={['results', '+2', 'resultId']}>
                <TagSelect after="+2" options={results} disableItem={firstResultValue} allowClear />
              </Form.Item>
              <Form.Item name={['results', '+1', 'resultId']}>
                <TagSelect
                  after="+1"
                  options={results}
                  disableItem={secondResultValue}
                  allowClear
                />
              </Form.Item>
            </div>
          </BlockWrapper>
        </div>
      </Form>
    </div>
  );
};
