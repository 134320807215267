import parse from 'html-react-parser';
import loadScript from 'load-script';
import { identity } from 'ramda';

import { isDefined } from '@/utils/common';

export const getIframe = (html: string) => {
  const parsedHtml = parse(html);

  if (parsedHtml && Array.isArray(parsedHtml) && parsedHtml.length > 0) {
    const iframe = parsedHtml.find((elem) => elem.type === 'iframe');

    const iframeElement = document.createElement('iframe');

    iframeElement.src = `${iframe.props.src}`;
    iframeElement.id = iframe.props.id;
    iframeElement.onload = iframe.props.onload;
    iframeElement.setAttribute('onload', iframe.props.onload);

    return iframeElement.outerHTML;
  }

  return null;
};

export const loadScriptsFromHtml = (html: string) => {
  const parsedHtml = parse(html);

  if (parsedHtml && Array.isArray(parsedHtml) && parsedHtml.length > 0) {
    parsedHtml.forEach((elem) => {
      if (elem.type === 'script') {
        const src = elem.props?.src || null;
        const id = `script-${elem.key}`;
        const existingScript = document.getElementById(`script-${elem.key}`);
        if (isDefined(id) && isDefined(src) && !existingScript) {
          loadScript(src, { attrs: { id } }, identity);
        }
      }
    });
  }
};
