import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetManyRolesQueryPrivate,
  GetManyRolesResponsePrivate,
  RoleResponsePrivate,
  UpdateRoleBodyPrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { createUrl } from '@/utils';

import { authGuardQuery } from './queries';

export const rolesApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getRolesList: build.query<
      GetManyRolesResponsePrivate,
      RelationsParams & Partial<GetManyRolesQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((role) => ({
          id: role.id,
          type: 'Roles' as const,
        })) || []),
        { id: 'List', type: 'Roles' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Roles),
      }),
    }),
    updateRole: build.mutation<RoleResponsePrivate, UpdateRoleBodyPrivate & { id: number }>({
      invalidatesTags: (result) => [{ id: result?.id, type: 'Roles' }],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(rolesApi.util.invalidateTags(['Roles']));
        });
      },
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Roles, id),
      }),
    }),
  }),
  reducerPath: 'rolesApi',
  tagTypes: ['Roles'],
});

export const { useGetRolesListQuery, useUpdateRoleMutation } = rolesApi;
