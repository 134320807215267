import { useEffect, useState } from 'react';

import { SSOProvider } from '@/services';

import { useConfig } from './useConfig';

export const useSSOProvider = () => {
  const [instance, setInstance] = useState<SSOProvider>(null);
  const {
    sso: { clientId, url },
  } = useConfig();

  useEffect(() => {
    setInstance(new SSOProvider(url, clientId));
  }, [clientId, url]);

  return instance;
};
