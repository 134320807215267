import {
  GetManyProjectsQueryPrivate,
  PermissionAction,
  PermissionResource,
  ProjectResponsePrivate,
} from '@tests/types';
import { Col, Row, Typography } from 'antd';
import { inc } from 'ramda';
import React, { useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { InfiniteScroll } from '@/components';
import { ProjectCard, ProjectModal, TestsListHeader } from '@/containers';
import { usePermissions } from '@/hooks';
import { projectsEntitySelector } from '@/selectors';
import { useGetProjectsListQuery } from '@/services';
import { isDefined } from '@/utils';

import styles from './styles.module.scss';

export const ProjectsPage = () => {
  const [page, setPage] = useState(0);
  const [showEditModal, setShowEditModal] = useState(false);
  const [editingProjectId, setEditingProjectId] = useState<number>(null);

  const fetchData = async () => {
    setPage(inc);
  };

  const getListQueryParams = useMemo(() => {
    const params = {
      offset: page * 30,
      order: '+id',
    } as Partial<GetManyProjectsQueryPrivate>;

    return params;
  }, [page]);

  const { data, isFetching } = useGetProjectsListQuery(
    { ...getListQueryParams, relations: ['logo'] },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Project, PermissionAction.Read),
    },
  );

  const projects: ProjectResponsePrivate<string>[] =
    useSelector(projectsEntitySelector.selectAll) || [];

  const onProjectEditClick = (id: number) => {
    setEditingProjectId(id);
    setShowEditModal(true);
  };

  const onModalClose = () => {
    setShowEditModal(false);
    setEditingProjectId(null);
  };

  return (
    <div className={styles.content}>
      <Row style={{ width: '100%' }}>
        <Col span={24}>
          <TestsListHeader hideSearch />
        </Col>
      </Row>
      <div>
        <Row>
          <Typography.Title level={4} style={{ fontSize: '21px' }}>
            Проекты
          </Typography.Title>
        </Row>
        <InfiniteScroll
          hasMore={isDefined(data) && data?.count > projects?.length}
          isFetching={isFetching}
          fetchData={fetchData}
        >
          <div className={styles.grid}>
            {projects?.map((project) => (
              <ProjectCard project={project} onProjectEditClick={onProjectEditClick} />
            ))}
          </div>
        </InfiniteScroll>
      </div>
      <div id="modal-container" style={{ position: 'relative', zIndex: 1000 }} />
      {showEditModal && !!editingProjectId && (
        <ProjectModal onClose={onModalClose} projectId={editingProjectId} />
      )}
    </div>
  );
};
