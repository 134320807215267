import { Col, Row, Typography } from 'antd';
import classNames from 'classnames';
import { always, equals, ifElse } from 'ramda';
import React from 'react';

import { SortDown, SortUp } from '@/assets';
import { SortDirections } from '@/constants';

import style from './styles.module.scss';

export interface SortField {
  label: string;
  value: string;
}

export type SortValue = Record<string, SortDirections>;

interface Props {
  fields: SortField[];
  onChange(sort: SortValue): void;
  value: SortValue;
}

const getReverseSort = ifElse(
  equals(SortDirections.Asc),
  always(SortDirections.Desc),
  always(SortDirections.Asc),
);

export const Sort: React.FC<Props> = ({ fields, onChange, value }) => {
  const onSortChange = (field: string) => () => {
    onChange({ [field]: getReverseSort(value[field]) });
  };

  const isSortDesc = (name: string) => value && value[name] === SortDirections.Desc;
  const isSortAsc = (name: string) => value && value[name] === SortDirections.Asc;
  const isSort = (name: string) => (value && isSortAsc(name)) || isSortDesc(name);

  return (
    <Row gutter={[20, 0]} className={style.container}>
      <>
        <Col>
          <Typography.Text className={style.sort}>Сортировать:</Typography.Text>
        </Col>
        <div className={style.items}>
          {fields.map(({ label, value }) => (
            <Col key={label} className={style.sortWrapper} onClick={onSortChange(value)}>
              <Row align="middle" gutter={[5, 0]}>
                <Col>
                  <Typography.Text
                    className={classNames(style.label, isSort(value) && style.selected)}
                  >
                    {label}
                  </Typography.Text>
                </Col>
                <Col className={classNames(style.iconWrapper, isSort(value) && style.selected)}>
                  {isSortAsc(value) ? <SortUp /> : <SortDown />}
                </Col>
              </Row>
            </Col>
          ))}
        </div>
      </>
    </Row>
  );
};
