import { CloseOutlined } from '@ant-design/icons';
import {
  Permission,
  PermissionResource,
  PermissionResourceName,
  RoleResponsePrivate,
} from '@tests/types';
import { Button, Col, Input, Modal, Row, Typography } from 'antd';
import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';

import { FiltersItem, MultiSelect } from '@/components';
import { permissionsEntitySelector } from '@/selectors';
import { useGetPermissionsListQuery, useUpdateRoleMutation } from '@/services';
import { groupByResource } from '@/utils';

import styles from './styles.module.scss';

type RoleEditorProps = {
  onClose: () => void;
  role: RoleResponsePrivate<string>;
  rolesRefetch: () => void;
};

export const RoleEditor: React.FC<RoleEditorProps> = ({ onClose, role, rolesRefetch }) => {
  const [name, setName] = useState(role.name);
  const [permissionsState, setPermissionsState] = useState(role.permissions);

  const { isFetching: isPermissionsFetching } = useGetPermissionsListQuery(
    {
      limit: Object.keys(PermissionResource).length * 5,
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const [updateBlock] = useUpdateRoleMutation();

  const handleSaveRole = async () => {
    const permissionIds = permissionsState.map(({ id }) => id);

    await updateBlock({
      id: role.id,
      name,
      permissionIds,
    });

    rolesRefetch();
    onClose();
  };

  const permissions = useSelector(permissionsEntitySelector.selectAll) || [];

  const handleChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (event && value !== role?.name) {
      setName(value);
    }
  };

  const handlePermissionsChange = (options: FiltersItem[], resourceName: string) => {
    const filteredPermissions = permissionsState.filter((p) => p.resource !== resourceName);
    const fromOptionsToPermissions = options.map(({ value: id }) => {
      const permission = permissions.find((p) => p.id === id);

      return permission;
    }) as Permission<Date | string>[];

    setPermissionsState([
      ...filteredPermissions,
      ...(fromOptionsToPermissions as Permission<Date>[]),
    ]);
  };

  return (
    <Modal
      open
      onCancel={onClose}
      getContainer={() => document.getElementById('modal-container')}
      mask={false}
      closable={false}
      footer={null}
      centered
      rootClassName={styles.editorWrapper}
      width={400}
    >
      <Col className={styles.editor}>
        <Row justify="space-between" className={styles.editorTitle}>
          <Typography.Text className={styles.editorTitleText}>Редактирование роли</Typography.Text>
          <CloseOutlined onClick={onClose} />
        </Row>
        <Row gutter={[0, 10]} className={styles.inputWrapper}>
          <Typography.Text strong type="secondary">
            Название роли
          </Typography.Text>
          <Input value={name} onChange={handleChangeName} />
        </Row>
        <Row>
          <Typography.Text strong type="secondary">
            Права доступа к интерактивам
          </Typography.Text>
        </Row>
        <Row justify="center" align="middle" className={styles.filters}>
          {Object.keys(PermissionResource).map((resourceName) => (
            <Row className={styles.filterRow}>
              <Typography.Text type="secondary">
                {PermissionResourceName[resourceName as keyof typeof PermissionResourceName] ||
                  resourceName}
              </Typography.Text>
              <MultiSelect
                allowClear={false}
                value={groupByResource(
                  permissionsState,
                  PermissionResource[resourceName as keyof typeof PermissionResource],
                )}
                loading={isPermissionsFetching}
                options={groupByResource(
                  permissions,
                  PermissionResource[resourceName as keyof typeof PermissionResource],
                )}
                name={resourceName}
                onChange={handlePermissionsChange}
                classname="employeeEdit"
              />
            </Row>
          ))}
        </Row>
        <Row gutter={[10, 0]} className={styles.editBtnWrapper} justify="end">
          <Col>
            <Button className={classNames(styles.editBtn, styles.editCloseBtn)} onClick={onClose}>
              Отменить
            </Button>
          </Col>
          <Col>
            <Button
              className={classNames(styles.editBtn, styles.editSaveBtn)}
              onClick={handleSaveRole}
              type="primary"
            >
              Сохранить
            </Button>
          </Col>
        </Row>
      </Col>
    </Modal>
  );
};
