import { createSlice } from '@reduxjs/toolkit';
import { RoleResponsePrivate } from '@tests/types';

import { rolesAdapter } from '@/adapters';
import { rolesApi } from '@/services';

interface State {
  changes?: Partial<RoleResponsePrivate>[];
  count: number;
  lastRequestId?: string;
  rolesAdapting: number[];
}

export const rolesSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(rolesApi.endpoints.getRolesList.matchPending, (state, action) => {
        if (action.meta.arg?.originalArgs?.offset === 0 && action.meta.arg.subscribe) {
          rolesAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(rolesApi.endpoints.getRolesList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          rolesAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
          state.changes = [];
        }
      });
  },
  initialState: rolesAdapter.getInitialState<State>({
    changes: [],
    count: 0,
    rolesAdapting: [],
  }),
  name: 'roles',
  reducers: {},
});

export const rolesReducer = rolesSlice.reducer;
