import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { answersAdapter } from '@/adapters';

import { stateSelector } from './state';

export const answersEntitySelector = answersAdapter.getSelectors<RootState>(prop('answers'));

export const answersSelector = createDraftSafeSelector(
  stateSelector,
  prop<RootState['answers']>('answers'),
);

export const hasAnswersUpdatingSelector = createDraftSafeSelector(
  answersSelector,
  ({ answersUpdating }) => answersUpdating.length > 0,
);

export const countAnswersSelector = createDraftSafeSelector(answersSelector, prop<number>('count'));
