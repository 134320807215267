import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { resultsAdapter } from '@/adapters';

import { stateSelector } from './state';

export const resultsEntitySelector = resultsAdapter.getSelectors<RootState>(prop('results'));

export const resultsSelector = createDraftSafeSelector(
  stateSelector,
  prop<RootState['results']>('results'),
);

export const hasResultsUpdatingSelector = createDraftSafeSelector(
  resultsSelector,
  ({ resultsUpdating }) => resultsUpdating.length > 0,
);

export const countResultsSelector = createDraftSafeSelector(resultsSelector, prop<number>('count'));
