import { useEffect, useState } from 'react';

import { useDebounce } from '@/hooks/useDebounce';
import { useUpdatingBlocks } from '@/hooks/useUpdatingBlocks';

export const useDisabledByUpdate = (delay = 2000) => {
  const [disabled, setDisabled] = useState(false);

  const anythingUpdating = useUpdatingBlocks();

  const debouncedDisable = useDebounce(() => setDisabled(false), delay);

  useEffect(() => {
    if (anythingUpdating) {
      setDisabled(true);
    } else {
      debouncedDisable.current();
    }
  }, [anythingUpdating]);

  return disabled;
};
