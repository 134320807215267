import { useSelector } from 'react-redux';

import {
  hasAnswersUpdatingSelector,
  hasQuestionsUpdatingSelector,
  hasResultsUpdatingSelector,
  hasTestsUpdatingSelector,
} from '@/selectors';

export const useUpdatingBlocks = () => {
  const hasAnswersUpdating = useSelector(hasAnswersUpdatingSelector);
  const hasQuestionsUpdating = useSelector(hasQuestionsUpdatingSelector);
  const hasTestsUpdating = useSelector(hasTestsUpdatingSelector);
  const hasResultsUpdating = useSelector(hasResultsUpdatingSelector);

  return hasQuestionsUpdating || hasAnswersUpdating || hasTestsUpdating || hasResultsUpdating;
};
