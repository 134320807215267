import classNames from 'classnames';
import { divide } from 'ramda';
import { FC, useMemo, useState } from 'react';
import { Cell, Pie, PieChart, ResponsiveContainer, Tooltip } from 'recharts';

import styles from './styles.module.scss';

interface Props {
  data: { label: string; value: number }[];
}

interface ItemProps {
  color: string;
  isActive: boolean;
  label: string;
  onClick: () => void;
  onMouseEnter: () => void;
  onMouseLeave: () => void;
  percentage: string;
}

const COLORS = ['#3263E9', '#82CDF2', '#F2BE80', '#F174BF', '#F0F1F5', '#687697'];
const COLORS_INACTIVE = ['#14285D', '#345261', '#614C33', '#602E4C', '#606062', '#2A2F3C'];

const CustomTooltip: FC<{
  activeIndex: number;
  data: any[];
}> = ({ activeIndex, data }) => (
  <div className={styles.customTooltip}>{`${data[activeIndex].percentage}%`}</div>
);

const StatsLabel: FC<ItemProps> = ({
  color,
  isActive,
  label,
  onClick,
  onMouseEnter,
  onMouseLeave,
  percentage,
}) => (
  <div
    className={classNames(styles.item, isActive && styles.active)}
    onClick={onClick}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
  >
    <div className={styles.itemSquare} style={{ backgroundColor: color }} />
    <div className={styles.itemText}>{label}</div>
    <div className={styles.itemPrecent}>{percentage}%</div>
  </div>
);

export const StatsPieChart: FC<Props> = ({ data }) => {
  const [activeIndexHover, setActiveIndexHover] = useState(-1);
  const [activeIndexClick, setActiveIndexClick] = useState(-1);

  const handleMouseEnter = (data: any, index: number) => {
    setActiveIndexHover(index);
  };

  const handleMouseLeave = (data: any, index: number) => {
    setActiveIndexHover(-1);
  };

  const activeIndex = useMemo(
    () => (activeIndexClick >= 0 ? activeIndexClick : activeIndexHover),
    [activeIndexClick, activeIndexHover],
  );

  // Обработчик события клика
  const handleClick = (data: any, index: number) => {
    if (index === activeIndexClick) {
      setActiveIndexClick(-1);
    } else {
      setActiveIndexClick(index);
    }
  };

  const dataWithTotal = useMemo(() => {
    const total = data.reduce((acc, current) => acc + current.value, 0);

    return data.map((item, index) => ({
      ...item,
      name: `cell-${index}`,
      percentage: ((item.value / total) * 100).toFixed(0),
    }));
  }, [data]);

  return (
    <>
      <div className={styles.chart}>
        <ResponsiveContainer width="100%">
          <PieChart>
            <Pie
              data={dataWithTotal}
              innerRadius={50}
              outerRadius={100}
              dataKey="value"
              cornerRadius={5}
              startAngle={90}
              activeIndex={activeIndex}
              endAngle={-270}
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              onClick={handleClick}
            >
              {data.map((entry, index) => (
                <Cell
                  // eslint-disable-next-line react/no-array-index-key
                  key={`cell-${index}`}
                  fill={
                    activeIndex < 0 || index === activeIndex
                      ? COLORS[index % COLORS.length]
                      : COLORS_INACTIVE[index % COLORS_INACTIVE.length]
                  }
                  cursor="pointer"
                  stroke="#1f2126"
                  strokeWidth={dataWithTotal.length === 1 ? 0 : 3}
                />
              ))}
            </Pie>
          </PieChart>
        </ResponsiveContainer>
        {activeIndex >= 0 && <CustomTooltip data={dataWithTotal} activeIndex={activeIndex} />}
      </div>
      <div className={styles.chartLables}>
        {dataWithTotal.map((item, index) => (
          <StatsLabel
            key={item.name}
            isActive={activeIndex === index}
            color={COLORS[index % COLORS.length]}
            label={item.label}
            onClick={() => handleClick(item, index)}
            onMouseEnter={() => handleMouseEnter(item, index)}
            onMouseLeave={() => handleMouseLeave(item, index)}
            percentage={item.percentage}
          />
        ))}
      </div>
    </>
  );
};
