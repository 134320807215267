import { FC, PropsWithChildren } from 'react';

import styles from './styles.module.scss';

interface Props {
  title: string;
}

export const ContentBlock: FC<PropsWithChildren<Props>> = ({ children, title }) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={styles.content}>{children}</div>
  </div>
);
