import React, { FC } from 'react';

import styles from './styles.module.scss';

interface Props {
  label: string;
  testCount: number;
}

export const TabHeadItem: FC<Props> = ({ label, testCount }) => (
  <div className={styles.tabHeadItem}>
    <div className={styles.label}>{label}</div>
    <div className={styles.testCount}>{testCount}</div>
  </div>
);
