import createSagaMiddleware from 'redux-saga';

import {
  answersApi,
  configApi,
  employeesApi,
  filesApi,
  projectsApi,
  questionsApi,
  resultsApi,
  testsApi,
  themesApi,
  usersApi,
} from '@/services';

export const sagaMiddleware = createSagaMiddleware();

export const middlewares = [
  sagaMiddleware,
  ...[
    configApi,
    testsApi,
    resultsApi,
    questionsApi,
    answersApi,
    projectsApi,
    themesApi,
    filesApi,
    employeesApi,
    usersApi,
  ].map((api) => api.middleware),
];
