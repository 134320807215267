import { Input } from 'antd';
import { isNil } from 'ramda';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  memo,
  useCallback,
  useEffect,
  useState,
} from 'react';

import { Search as SearchIcon } from '@/assets';
import { isDefined } from '@/utils';

import { CreateDropdown } from '../CreateDropdown/CreateDropdown';
import { UserDropdown } from '../UserDropdown';
import styles from './styles.module.scss';

interface Props {
  hideSearch?: boolean;
  onSearch?(value: string): void;
  placeholderText?: string;
  term?: string;
}

const { Search } = Input;

export const TestsListHeader: React.FC<Props> = memo((props) => {
  const { hideSearch, onSearch, placeholderText = 'Поиск по названию', term } = props;
  const [searchTerm, setSearchTerm] = useState<string>(term);

  const handleChangeSearchTerm: ChangeEventHandler<HTMLInputElement> = useCallback((event) => {
    setSearchTerm(event.target.value);
  }, []);

  const handlePressEnter: KeyboardEventHandler<HTMLInputElement> = useCallback(() => {
    if (isDefined(searchTerm) && isDefined(onSearch)) {
      onSearch(searchTerm);
    }
  }, [searchTerm, onSearch]);

  useEffect(() => {
    if (isNil(term)) {
      setSearchTerm(undefined);
    }
  }, [term]);

  return (
    <div className={styles.headerWrapper}>
      <UserDropdown />
      {!hideSearch && (
        <Search
          className={styles.search}
          bordered={false}
          size="large"
          placeholder={placeholderText}
          prefix={<SearchIcon width={24} height={24} className={styles.prefix} />}
          onChange={handleChangeSearchTerm}
          onPressEnter={handlePressEnter}
          onSearch={onSearch}
          value={searchTerm}
          allowClear
        />
      )}
      <CreateDropdown />
    </div>
  );
});
