import { PlusOutlined } from '@ant-design/icons';
import { CrosswordOptions, TestType } from '@tests/types';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { CrosswordThemes, RoutesPath } from '@/constants';
import { useCreateTestMutation } from '@/services';
import { isCrossword, isSuccessResult } from '@/utils';

import styles from './styles.module.scss';

type Props = {
  empty?: boolean;
  type: TestType;
};

const getCreationButtonTitle = cond<[TestType], Nullable<string>>([
  [equals(TestType.Test), always('Тест')],
  [equals(TestType.Extended), always('Расширенный тест')],
  [equals(TestType.Quiz), always('Квиз')],
  [equals(TestType.Poll), always('Голосование')],
  [equals(TestType.Crossword), always('Кроссворд')],
  [T, always(null)],
]);

const getCreationButtonDescription = cond<[TestType], Nullable<string>>([
  [equals(TestType.Test), always('Результат и баллы только в конце')],
  [equals(TestType.Quiz), always('Признаки правильного ответа сразу')],
  [equals(TestType.Poll), always('В результате выводится статистика')],
  [equals(TestType.Crossword), always('Вопросы с одним ответом')],
  [T, always(null)],
]);

export const CreationButton: React.FC<Props> = ({ empty = false, type }) => {
  const [createTest] = useCreateTestMutation();
  const navigate = useNavigate();

  const handleCreateButtonClick = async (type: TestType, newTab = false) => {
    const creationParams: {
      crosswordOptions?: CrosswordOptions;
      projectIds: any[];
      type: TestType;
    } = {
      projectIds: [],
      type,
    };

    if (isCrossword(type)) {
      creationParams.crosswordOptions = CrosswordThemes.Default;
    }

    const result = await createTest(creationParams);

    if (isSuccessResult(result)) {
      if (newTab) {
        window.open(generatePath(RoutesPath.TestEdit, { idOrAlias: result?.data?.id }), '_blank');
      } else {
        navigate(generatePath(RoutesPath.TestEdit, { idOrAlias: result?.data?.id }));
      }
    }
  };

  if (empty) {
    return (
      <div
        className={classNames(styles.buttonEmpty, styles[type])}
        // eslint-disable-next-line consistent-return
        onMouseDown={(e) => {
          if (e.button === 1) {
            e.preventDefault();
            e.stopPropagation();

            return false;
          }
        }}
        onMouseUp={(e) => {
          e.preventDefault();
          handleCreateButtonClick(type, e?.nativeEvent?.which === 2);
        }}
      >
        <Row align="middle">
          <Col span={24} className={styles.title}>
            {getCreationButtonTitle(type)}
          </Col>
        </Row>
      </div>
    );
  }

  return (
    <div
      className={classNames(styles.buttonWrapper, styles[type])}
      // eslint-disable-next-line consistent-return
      onMouseDown={(e) => {
        if (e.button === 1) {
          e.preventDefault();
          e.stopPropagation();

          return false;
        }
      }}
      onMouseUp={(e) => {
        e.preventDefault();
        handleCreateButtonClick(type, e?.nativeEvent?.which === 2);
      }}
    >
      <Row align="middle">
        <Col span={22}>
          <Row gutter={[5, 5]}>
            <Col span={24} className={styles.buttonTitle}>
              {getCreationButtonTitle(type)}
            </Col>
            <Col span={24} className={styles.buttonDescription}>
              {getCreationButtonDescription(type)}
            </Col>
          </Row>
        </Col>
        <Col span={2}>
          <PlusOutlined className={styles.plus} />
        </Col>
      </Row>
    </div>
  );
};
