import { Button, Col, Row, Typography } from 'antd';

import styles from './styles.module.scss';

interface IProps {
  isAllHighlighted: boolean;
  isHighlightActive: boolean;
  onHighlightAll: () => void;
  onHighlightMode: () => void;
}

export const HighlightButtons: React.FC<IProps> = ({
  isAllHighlighted,
  isHighlightActive,
  onHighlightAll,
  onHighlightMode,
}) => {
  const desktopButtonBlock = () => (
    <>
      <Col className={styles.desktopHighlight} onClick={onHighlightAll}>
        <Typography.Text className={styles.highlightTxt}>
          {isAllHighlighted ? 'Снять выделение' : 'Выделить все'}
        </Typography.Text>
      </Col>
      <Col className={styles.desktopHighlight} onClick={onHighlightMode}>
        <Typography.Text className={styles.highlightTxt}>Отменить</Typography.Text>
      </Col>
    </>
  );

  const mobileButtonBlock = () => (
    <>
      <Col xs={12} md={12} lg={0} onClick={onHighlightAll}>
        <Button className={styles.highlightBtn} type="primary">
          {isAllHighlighted ? 'Снять выделение' : 'Выделить все'}
        </Button>
      </Col>
      <Col xs={12} md={12} lg={0} onClick={onHighlightMode}>
        <Button className={styles.highlightBtn}>Отменить</Button>
      </Col>
    </>
  );

  return (
    <Row justify="space-between" className={styles.highlightButtons} gutter={[20, 0]}>
      {isHighlightActive ? (
        <>
          {desktopButtonBlock()}
          {mobileButtonBlock()}
        </>
      ) : (
        <>
          <Col xs={24} lg={0} onClick={onHighlightMode}>
            <Button className={styles.highlightBtn} onClick={onHighlightMode} type="primary">
              Выделить интерактивы
            </Button>
          </Col>

          <Col xs={0} lg={24} onClick={onHighlightMode}>
            <Typography.Text className={styles.highlightTxt} onClick={onHighlightMode}>
              Выделить интерактивы
            </Typography.Text>
          </Col>
        </>
      )}
    </Row>
  );
};
