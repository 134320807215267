import { createSlice } from '@reduxjs/toolkit';
import { compose, equals, not } from 'ramda';

import { answersAdapter } from '@/adapters';
import { answersApi } from '@/services';

interface State {
  answersUpdating: number[];
  count: number;
  lastRequestId?: string;
}

export const answersSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(answersApi.endpoints.getAnswersList.matchPending, (state, action) => {
        if (action.meta.arg?.originalArgs?.offset === 0 && action.meta.arg.subscribe) {
          answersAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(answersApi.endpoints.getAnswersList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          answersAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
        }
      })
      .addMatcher(answersApi.endpoints.updateAnswer.matchPending, (state, action) => {
        state.answersUpdating = [...state.answersUpdating, action.meta.arg.originalArgs.id];
      })
      .addMatcher(answersApi.endpoints.updateAnswer.matchFulfilled, (state, action) => {
        state.answersUpdating = state.answersUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs.id)),
        );
      })
      .addMatcher(answersApi.endpoints.updateAnswer.matchRejected, (state, action) => {
        state.answersUpdating = state.answersUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs.id)),
        );
      })
      .addMatcher(answersApi.endpoints.deleteAnswer.matchFulfilled, (state, action) => {
        answersAdapter.removeOne(state, action.meta.arg.originalArgs);
      });
  },
  initialState: answersAdapter.getInitialState<State>({
    answersUpdating: [],
    count: 0,
  }),
  name: 'answers',
  reducers: {},
});

export const answersReducer = answersSlice.reducer;
