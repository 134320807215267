import { Form } from 'antd';
import React from 'react';

import { Switch } from '@/atoms';

import { FormBlock } from '../Wrappers/FormBlock';

type Props = {
  checked: boolean;
  formItemName?: string;
  id: number;
  infoText?: string;
  updateBlock: any;
};

export const SwitcherBlock: React.FC<Props> = ({
  checked,
  formItemName = '',
  id,
  infoText,
  updateBlock,
}) => {
  const [form] = Form.useForm();

  return (
    <FormBlock
      testId={id}
      form={form}
      initialValues={{ [formItemName]: checked }}
      updateBlock={updateBlock}
      inverted
    >
      <Form.Item name={formItemName} valuePropName="checked">
        <Switch text={infoText} />
      </Form.Item>
    </FormBlock>
  );
};
