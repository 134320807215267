import disableScroll from 'disable-scroll';
import { useEffect } from 'react';

export const useDisableScroll = () => {
  useEffect(() => {
    disableScroll.on();

    return () => {
      disableScroll.off();
    };
  }, []);
};
