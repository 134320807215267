import React, { SVGAttributes } from 'react';

import { BlackWhiteCrosswordTheme, DefaultCrosswordTheme, LightCrosswordTheme } from '@/assets';

export const CrosswordThemes = {
  BlackWhite: {
    cellBackground: '#FFF',
    cellBorder: '#DEDFE3',
    clueHighlightBackground: '#F4F5F6',
    focusBackground: '#1F2126',
    focusNumberColor: '#FFF',
    focusTextColor: '#FFF',
    focusedCellBorder: '#1F2126',
    gridBackground: '#FFF',
    highlightBackground: '#F4F5F6',
    highlightCellBorder: '#1F2126',
    numberColor: '#1F2126',
    textColor: '#1F2126',
  },
  Default: {
    cellBackground: '#181A20',
    cellBorder: '#343B4C',
    clueCorrectTextColor: '#6D727F',
    clueCorrectTextDecoration: 'line-through',
    clueHighlightBackground: '#3263e9',
    clueTextColor: '#FFF',
    focusBackground: '#3263e9',
    focusNumberColor: '#FFF',
    focusTextColor: '#fff',
    focusedCellBorder: '#3263E9',
    gridBackground: 'transparent',
    highlightBackground: '#1F2126',
    highlightCellBorder: '#3263e9',
    numberColor: '#fff',
    textColor: '#fff',
  },
  Light: {
    cellBackground: '#FFF',
    cellBorder: '#DEDFE3',
    clueHighlightBackground: '#E7F3FD',
    focusBackground: '#BBE1FB',
    focusNumberColor: '#1F2126',
    focusTextColor: '#1F2126',
    focusedCellBorder: '#93C8EC',
    gridBackground: '#FFF',
    highlightBackground: '#E7F3FD',
    highlightCellBorder: '#93C8EC',
    numberColor: '#1F2126',
    textColor: '#1F2126',
  },
};

type CrosswordTypeSelectItem = {
  icon: React.FC<SVGAttributes<SVGElement>>;
  label: string;
  value: string;
};

export const crosswordThemes: Array<CrosswordTypeSelectItem> = [
  {
    icon: DefaultCrosswordTheme,
    label: `Основной`,
    value: JSON.stringify(CrosswordThemes.Default),
  },
  {
    icon: BlackWhiteCrosswordTheme,
    label: `Черно-белый`,
    value: JSON.stringify(CrosswordThemes.BlackWhite),
  },
  { icon: LightCrosswordTheme, label: `Светлая`, value: JSON.stringify(CrosswordThemes.Light) },
];
