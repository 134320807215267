import { useRef } from 'react';

export const useDebounce = (execute: (...props: any) => Promise<void> | void, delay = 600) => {
  const searchTimerId = useRef<NodeJS.Timeout>();

  return useRef((...args: any) => {
    clearTimeout(Number(searchTimerId.current));

    searchTimerId.current = setTimeout(() => execute(...args), delay);
  });
};
