import type { BaseQueryFn } from '@reduxjs/toolkit/dist/query';
import { Http } from '@status/codes';
import { Mutex } from 'async-mutex';

import { axiosBaseQuery, QueryArg } from './axios';

const mutex = new Mutex();

export const authGuardQuery: BaseQueryFn = async (
  request: QueryArg | string,
  api: any,
  extraOptions: Record<string, any>,
): Promise<any> => {
  await mutex.waitForUnlock();
  const proxyRequest = {
    ignoreErrors: [Http.Unauthorized],
    ...(typeof request === 'string' ? { url: request } : request),
  };

  const result = await axiosBaseQuery(proxyRequest, api, extraOptions);

  if (result?.error?.status === Http.Unauthorized && localStorage.getItem('token')) {
    if (!mutex.isLocked()) {
      const release = await mutex.acquire();

      try {
        const refreshResult = await axiosBaseQuery(
          { ignoreErrors: [Http.Forbidden], url: '/auth/refresh' },
          api,
          {
            ...extraOptions,
            credentials: 'include',
          },
        );

        if (refreshResult.data) {
          localStorage.setItem('token', refreshResult.data.accessToken);

          return await axiosBaseQuery(request, api, extraOptions);
        }

        localStorage.removeItem('token');
      } finally {
        release();
      }
    } else {
      await mutex.waitForUnlock();

      return axiosBaseQuery(proxyRequest, api, extraOptions);
    }
  }

  return result;
};
