/* eslint-disable react/no-unused-prop-types */
import { TestType } from '@tests/types';
import { Spin, Typography } from 'antd';
import { omit } from 'ramda';
import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { InfiniteScrollComponent } from '@/components';
import { testsEntitySelector } from '@/selectors';
import { useGetTestsListQuery } from '@/services';
import { isDefined } from '@/utils';

import { useFilterPage } from '../../hooks';
import { TestCardNew } from '../TestCardNew';
import styles from './styles.module.scss';

type Props = {
  highlightedIds: number[];
  isHighlightActive: boolean;
  onHandleHighlight: (ids: number[]) => void;
  order?: string;
  showDeleted: boolean;
  type?: TestType | undefined;
};

export const TestsList: React.FC<Props> = ({
  highlightedIds,
  isHighlightActive,
  onHandleHighlight,
  showDeleted,
}) => {
  const { filter, setFilter } = useFilterPage();
  const { offset = 0, page = 1 } = useMemo(() => filter, [filter]);

  const fetchData = async () => {
    await setFilter({ offset: page * 30, page: Number(page) + 1 });
  };

  const { data, isFetching, isLoading } = useGetTestsListQuery(
    {
      ...omit(['page'], filter),
      order: filter.order || '-createdAt',
      relations: ['projects', 'projects.logo'],
    },
    {
      refetchOnMountOrArgChange: true,
    },
  );

  const tests = useSelector(testsEntitySelector.selectAll) || [];

  if ((page === 0 || offset === 0) && (isFetching || isLoading)) {
    return (
      <Spin
        size="large"
        style={{
          padding: '10px',
          width: '100%',
        }}
      />
    );
  }

  if (tests?.length === 0) {
    return (
      <Typography.Text className={styles.stub}>
        По вашему запросу ничего не найдено.
      </Typography.Text>
    );
  }

  return (
    <InfiniteScrollComponent
      hasMore={isDefined(data) && data?.count > tests?.length}
      length={tests?.length || 0}
      fetchData={fetchData}
      offset={offset}
    >
      <div className={styles.grid}>
        {tests?.map((test) => (
          <TestCardNew
            test={test}
            key={test.id}
            isHighlightActive={isHighlightActive}
            showDeleted={showDeleted}
            onHandleHighlight={onHandleHighlight}
            highlightedIds={highlightedIds}
          />
        ))}
      </div>
    </InfiniteScrollComponent>
  );
};
