import classNames from 'classnames';
import { FC, ReactNode } from 'react';

import styles from './styles.module.scss';

interface Props {
  icon?: ReactNode;
  sup?: number;
  text: string;
}
export const StatsInfoText: FC<Props> = ({ icon, sup, text }) => (
  <div className={styles.container}>
    <div className={styles.wrapper}>
      <span className={styles.text}>{text}</span>
      {sup && (
        <sup className={classNames(styles.sup, sup >= 0 ? styles.grow : styles.down)}>{sup}</sup>
      )}
    </div>
    {icon}
  </div>
);
