import { Http } from '@status/codes';
import { notification } from 'antd';
import axios, { Method } from 'axios';
import { isEmpty, reject } from 'ramda';

import { configApiRootSelector } from '@/selectors';
import { stringifyQueryString } from '@/utils';

export type QueryArg = {
  contentType?: string;
  data?: any;
  headers?: Record<string, string>;
  ignoreErrors?: number[];
  method?: Method;
  onUploadProgress?(progressEvent: any): void;
  params?: Record<string, any> | void;
  url: string;
  withAuth?: boolean;
  withCredentials?: boolean;
};

const Api = axios.create({
  headers: {
    'Content-Type': 'application/json',
  },
  paramsSerializer: {
    serialize: stringifyQueryString({ arrayFormat: 'bracket' }),
  },
  withCredentials: true,
});

export const axiosBaseQuery = async (
  request: QueryArg | string,
  state: any,
  extraOptions: Record<string, any>,
): Promise<any> => {
  const {
    url = request as string,
    method = 'GET',
    data = undefined,
    params = null,
    onUploadProgress = null,
    contentType = null,
    ignoreErrors = [],
    withAuth = true,
    withCredentials = true,
    headers = {},
  } = typeof request === 'string' ? {} : request;

  try {
    const baseURL = configApiRootSelector(state.getState() as RootState) || window.location.origin;

    const queryHeaders: Record<string, string> = {
      ...headers,
      'Content-Type': contentType ?? 'application/json',
    };

    if (withAuth) {
      const token = localStorage.getItem('token');
      queryHeaders.Authorization = `Bearer ${token}`;
    }

    const result = await Api(url, {
      baseURL,
      data,
      headers: queryHeaders,
      method,
      onUploadProgress,
      params: params ? reject(isEmpty, params) : null,
      withCredentials,
      ...extraOptions,
    });

    return { data: result.data };
  } catch (axiosError: any) {
    const error = {
      data: axiosError.response?.data ?? axiosError?.message ?? '',
      status: axiosError.response?.status ?? Http.BadRequest,
    };

    /** ToDo Handle and translate 400 errors */
    if (error?.status >= Http.BadRequest) {
      // eslint-disable-next-line no-console
      console.error('apiError...', { error });

      if (!ignoreErrors.includes(error.status)) {
        notification.error({
          description: `/${url} (${method}) ${JSON.stringify(error.data)}`,
          duration: 60,
          message: `Ошибка выполнения запроса (${error?.status ?? ''})`,
          placement: 'bottomRight',
        });
      }
    }

    return { error };
  }
};
