import { Col, Row, Tooltip, Typography } from 'antd';
import classNames from 'classnames';

import { CloseToolbar, Restore, Trash } from '@/assets';

import styles from './styles.module.scss';

interface IProps {
  highlightedCount: number;
  onDelete: () => void;
  onHighlight: () => void;
  onRestore: () => Promise<void>;
  showDeleted: boolean;
}

export const HighlightToolbar: React.FC<IProps> = ({
  highlightedCount,
  onDelete,
  onHighlight,
  onRestore,
  showDeleted,
}) => {
  const isHighlightedCountZero = highlightedCount === 0;

  return (
    <div className={styles.toolbarWrapper}>
      <Row className={styles.hlToolbar}>
        <Col className={styles.countWrapper}>
          <Typography.Text>Выделено интерактивов: </Typography.Text>
          <Typography.Text className={styles.count}>{highlightedCount}</Typography.Text>
        </Col>
        <Col
          className={classNames(styles.deleteWrapper, isHighlightedCountZero && styles.disabled)}
        >
          {showDeleted ? (
            <Tooltip title={isHighlightedCountZero ? 'Ничего не выделено' : 'Восстановить'}>
              <div
                className={classNames(
                  styles.iconWrapper,
                  isHighlightedCountZero && styles.disabled,
                )}
              >
                <Restore
                  onClick={() => {
                    if (!isHighlightedCountZero) onRestore();
                  }}
                />
              </div>
            </Tooltip>
          ) : (
            <Tooltip title={isHighlightedCountZero ? 'Ничего не выделено' : 'Удалить'}>
              <div
                className={classNames(
                  styles.iconWrapper,
                  isHighlightedCountZero && styles.disabled,
                )}
              >
                <Trash
                  onClick={() => {
                    if (!isHighlightedCountZero) onDelete();
                  }}
                />
              </div>
            </Tooltip>
          )}
        </Col>
        <Col className={styles.closeWrapper} onClick={onHighlight}>
          <Tooltip title="Отменить">
            <div className={styles.iconWrapper}>
              <CloseToolbar />
            </div>
          </Tooltip>
        </Col>
      </Row>
    </div>
  );
};
