import { TestType } from '@tests/types';
import { Button, Dropdown, MenuProps } from 'antd';
import classNames from 'classnames';

import { DropdownArrow, Plus } from '@/assets';

import { CreationButton } from '../../components';
import styles from './styles.module.scss';

export const CreateDropdown = () => {
  const items: MenuProps['items'] = [
    {
      key: '0',
      label: <CreationButton type={TestType.Test} empty />,
    },
    {
      type: 'divider',
    },
    {
      key: '1',
      label: <CreationButton type={TestType.Extended} empty />,
    },
    {
      type: 'divider',
    },
    {
      key: '2',
      label: <CreationButton type={TestType.Quiz} empty />,
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      label: <CreationButton type={TestType.Poll} empty />,
    },
    {
      type: 'divider',
    },
    {
      key: '4',
      label: <CreationButton type={TestType.Crossword} empty />,
    },
  ];

  return (
    <Dropdown
      rootClassName={styles.dropDown}
      menu={{ items }}
      trigger={['click']}
      destroyPopupOnHide
      autoAdjustOverflow={false}
      overlayClassName={styles.overlay}
    >
      <Button className={classNames(styles.primaryButton)}>
        <Plus className={styles.plus} />
        <span>Новый интерактив</span>
        <DropdownArrow className={styles.dropdownArrow} />
      </Button>
    </Dropdown>
  );
};
