export enum ButtonType {
  Primary = 'primary',
  Secondary = 'secondary',
  Text = 'text',
}

export enum ButtonSize {
  Regular = 'regular',
  Small = 'small',
}
