import { Col, Row } from 'antd';
import React from 'react';

export const NotFound: React.FC = () => (
  <Row justify="center">
    <Col span={24}>404</Col>
    <Col span={24}>страница не найдена</Col>
    <Col span={24}>но можно вернуться ко всем публикациям</Col>
  </Row>
);
