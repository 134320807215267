import { createApi } from '@reduxjs/toolkit/query/react';
import * as yup from 'yup';

import { authGuardQuery } from './queries';

const schema: yup.SchemaOf<Config> = yup.object().shape({
  apiUrl: yup.string().required(),
  env: yup.string().default('development'),
  sso: yup.object().shape({
    clientId: yup.string().default(''),
    url: yup.string().default(''),
  }),
  upload: yup.object().shape({
    maxSizeImage: yup.number().default(1),
    timeFileCheck: yup.number().default(10),
  }),
});

export const configApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (builder) => ({
    getConfig: builder.query<Config, void>({
      query: () => ({
        method: 'GET',
        url: 'config.json',
        withAuth: false,
      }),
      transformResponse: (response) => schema.validateSync(response),
    }),
  }),
  reducerPath: 'configApi',
});

export const { useGetConfigQuery } = configApi;
