import { Provider as ProviderEnum } from '@/constants';

export default abstract class Provider {
  private provider: ProviderEnum;

  private authUrl: string;

  private clientId: string;

  constructor(provider: ProviderEnum, authUrl: string, clientId: string) {
    this.provider = provider;
    this.authUrl = authUrl;
    this.clientId = clientId;
  }

  public getCallbackUrl(): string {
    return `${window.location.origin}/${this.provider}/auth/callback`;
  }

  public getAuthUrl(): string {
    return `${this.authUrl}/oauth/authorization?response_type=code&client_id=${
      this.clientId
    }&redirect_uri=${encodeURI(this.getCallbackUrl())}`;
  }
}
