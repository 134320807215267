import { PermissionAction, PermissionResource } from '@tests/types';
import { inc } from 'ramda';
import { Dispatch, SetStateAction, useState } from 'react';
import { useSelector } from 'react-redux';

import { usePermissions } from '@/hooks';
import { projectsEntitySelector } from '@/selectors';
import { useGetProjectsListQuery } from '@/services';
import { FiltersItem } from '@/types';

import { MultiSelect } from '../MultiSelect';

type FiltersProps = {
  classname?: string;
  onChange?: Dispatch<SetStateAction<FiltersItem[]>>;
  value?: FiltersItem[];
};

export const ProjectsFilter: React.FC<FiltersProps> = ({ classname, onChange, value }) => {
  const [page, setPage] = useState(0);
  const { data: projectsData, isFetching } = useGetProjectsListQuery(
    {
      offset: page * 30,
    },
    {
      refetchOnMountOrArgChange: true,
      skip: !usePermissions(PermissionResource.Project, PermissionAction.Read),
    },
  );

  const projects =
    useSelector(projectsEntitySelector.selectAll)?.map((item) => ({
      label: item.name,
      value: item.id,
    })) || [];
  const hasMore = projectsData && projectsData?.count > projects?.length;

  const fetchData = async () => {
    setPage(inc);
  };

  return (
    <MultiSelect
      value={value || []}
      loading={isFetching}
      options={projects}
      hasMore={hasMore}
      fetchData={fetchData}
      onChange={onChange}
      classname={classname}
    />
  );
};
