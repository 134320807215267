import { MAX_LEN_QUESTION_TEXT } from '@tests/types';
import { Form, Input, Typography } from 'antd';
import { equals, pick } from 'ramda';
import React, { ChangeEventHandler, useCallback, useState } from 'react';

import { FormBlockProps } from '@/containers/Blocks/Wrappers';
import { useDebounce, useGetValidationError } from '@/hooks';
import { useUpdateQuestionMutation } from '@/services';

import styles from '../styles.module.scss';

const { TextArea } = Input;

type Props = {
  id: number;
  text: string;
};

export const CrosswordQuestion: React.FC<Props> = ({ id, text }) => {
  const [form] = Form.useForm();

  const [questionCount, setQuestionCount] = useState(text?.length);
  const [questionText, setQuestionText] = useState(text);

  const [updateQuestionBlock] = useUpdateQuestionMutation();

  const { getFieldsValue, submit } = form;
  const [previousFormValues, setPreviousFormValues] = useState({ questionText } || {});

  const questionError = useGetValidationError({ field: 'text', targetId: id });

  const onQuestionChange = useCallback((text: ChangeEventHandler<HTMLTextAreaElement>) => {
    setQuestionCount(text?.length);
  }, []);

  const handleFormChangeHandler = useDebounce(() => {
    submit();
  }, 300);

  const handleFinish = useCallback(async () => {
    const formValues = getFieldsValue();

    await updateQuestionBlock({
      ...{ text: formValues?.questionText },
      id,
    });
  }, [getFieldsValue, id, updateQuestionBlock]);

  const handleValuesChangeHandler: FormBlockProps['onValuesChange'] = useCallback(
    (changedValues: any) => {
      const formValuesToCompare = pick(Object.keys(changedValues))(previousFormValues);

      if (equals(changedValues, formValuesToCompare)) {
        return;
      }

      setPreviousFormValues({ ...previousFormValues, ...changedValues });

      onQuestionChange(changedValues?.questionText);
      handleFormChangeHandler.current();
    },
    [handleFormChangeHandler, onQuestionChange, previousFormValues],
  );

  return (
    <Form
      name={`question-${id}`}
      layout="vertical"
      validateTrigger="onKeyPress"
      form={form}
      initialValues={{ questionText }}
      onValuesChange={handleValuesChangeHandler}
      onFinish={handleFinish}
      key={id}
    >
      <Typography.Text className={styles.areaTitle}>Вопрос</Typography.Text>
      <Form.Item
        name="questionText"
        validateStatus={questionError && 'error'}
        help={questionError?.message}
      >
        <TextArea
          className={styles.questionInput}
          autoSize
          rows={1}
          bordered
          maxLength={MAX_LEN_QUESTION_TEXT}
          placeholder="Напишите вопрос"
        />
      </Form.Item>
      {MAX_LEN_QUESTION_TEXT && (
        <Typography.Text className={styles.countText}>
          {questionCount || 0}/{MAX_LEN_QUESTION_TEXT}
        </Typography.Text>
      )}
    </Form>
  );
};
