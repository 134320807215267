import { QuestionResponsePrivate } from '@tests/types';
import { equals, prop, propEq, uniqBy } from 'ramda';

export const concatChangesQuestions = (
  changes: Partial<QuestionResponsePrivate>[],
  questions: QuestionResponsePrivate[],
) =>
  questions.map((item) => {
    const changedSort = changes.find(propEq(item.id, 'id'));

    if (changedSort) {
      return {
        ...item,
        ...changedSort,
      };
    }

    return item;
  });

export const getFinalChanges = (
  changes: Partial<QuestionResponsePrivate>[],
  defaultValues: Partial<QuestionResponsePrivate>[],
) =>
  uniqBy(prop('id'), changes).filter(
    (item) => !equals(item, defaultValues.find(propEq(item.id, 'id'))),
  );
