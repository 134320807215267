import type { DragEndEvent } from '@dnd-kit/core/dist/types';
import { arrayMove } from '@dnd-kit/sortable';
import {
  always,
  defaultTo,
  equals,
  findIndex,
  ifElse,
  join,
  lensIndex,
  map,
  over,
  pipe,
  propEq,
  reverse,
  toPairs,
} from 'ramda';

import { SortDirections } from '@/constants';

import { isDefined } from './common';

export const getSortValuesFunc =
  <T extends { id: number; sortOrder: number }>(values: T[]) =>
  (event: DragEndEvent): Array<T> => {
    const { active, over } = event;

    if (isDefined(over) && active.id !== over.id) {
      const oldIndex = findIndex(propEq(active.id, 'id'), values);
      const newIndex = findIndex(propEq(over.id, 'id'), values);

      return arrayMove<T>(values, oldIndex, newIndex).reduce((acc, value, index) => {
        if (value.sortOrder === index) {
          return acc;
        }

        return [...acc, { ...value, sortOrder: index }];
      }, []);
    }

    return [];
  };

export const getIds = <T extends { id: number; sortOrder: number }>(values: T[]): number[] =>
  values.map((value) => value.id);

export const sortToString: (sort: Record<string, SortDirections>) => string = pipe(
  defaultTo({}),
  toPairs,
  // @ts-ignore
  map(
    pipe(
      reverse,
      // @ts-ignore
      over(lensIndex(0), ifElse(equals(SortDirections.Desc), always('-'), always('+'))),
      join(''),
    ),
  ),
  join(','),
);

export const sortFromString = (sort: string) => {
  if (sort) {
    const sortDirection = sort.slice(0, 1);
    const sortField = sort.slice(1);

    return { [sortField]: sortDirection === '-' ? SortDirections.Desc : SortDirections.Asc };
  }

  return { createdAt: SortDirections.Desc };
};
