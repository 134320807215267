import { createSlice } from '@reduxjs/toolkit';
import { QuestionResponsePrivate } from '@tests/types';
import { compose, equals, not } from 'ramda';

import { questionsAdapter } from '@/adapters';
import { questionsApi } from '@/services';
import { getFinalChanges } from '@/utils';

interface State {
  changes?: Partial<QuestionResponsePrivate>[];
  count: number;
  lastRequestId?: string;
  questionsUpdating: number[];
}

export const questionsSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(questionsApi.endpoints.getQuestionsList.matchPending, (state, action) => {
        if (action.meta.arg.subscribe) {
          questionsAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(questionsApi.endpoints.getQuestionsList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          questionsAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
          state.changes = [];
        }
      })
      .addMatcher(questionsApi.endpoints.updateQuestion.matchPending, (state, action) => {
        state.questionsUpdating = [...state.questionsUpdating, action.meta.arg.originalArgs.id];
      })
      .addMatcher(questionsApi.endpoints.updateQuestion.matchFulfilled, (state, action) => {
        state.questionsUpdating = state.questionsUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs.id)),
        );
      })
      .addMatcher(questionsApi.endpoints.updateQuestion.matchRejected, (state, action) => {
        state.questionsUpdating = state.questionsUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs.id)),
        );
      })
      .addMatcher(questionsApi.endpoints.deleteQuestion.matchFulfilled, (state, action) => {
        questionsAdapter.removeOne(state, action.meta.arg.originalArgs);
      });
  },
  initialState: questionsAdapter.getInitialState<State>({
    changes: [],
    count: 0,
    questionsUpdating: [],
  }),
  name: 'questions',
  reducers: {
    changeQuestionsOrder(state, action) {
      const questions = questionsAdapter.getSelectors().selectAll(state);
      state.changes = getFinalChanges([...action.payload, ...state.changes], questions);
    },
  },
});

export const { changeQuestionsOrder } = questionsSlice.actions;
export const questionsReducer = questionsSlice.reducer;
