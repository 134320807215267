import { BackgroundPlace, TestType } from '@tests/types';
import { Checkbox, Form } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, map, pipe, T } from 'ramda';
import React, { FC } from 'react';

import styles from './styles.module.scss';

const getBackgroundPaceTitle = cond<[BackgroundPlace], Nullable<string>>([
  [equals(BackgroundPlace.FirstPage), always('Стартовая страница')],
  [equals(BackgroundPlace.IntermediateResult), always('Промежуточный результат')],
  [equals(BackgroundPlace.Question), always('Вопросы')],
  [equals(BackgroundPlace.Result), always('Результат')],
  [T, always(null)],
]);

const getBackgroundPlaceOption = (place: BackgroundPlace) => ({
  label: getBackgroundPaceTitle(place),
  value: place,
});

const getBackgroundPlaceList = cond<[TestType], BackgroundPlace[]>([
  [equals(TestType.Quiz), always(Object.values(BackgroundPlace))],
  [
    equals(TestType.Test),
    always([BackgroundPlace.FirstPage, BackgroundPlace.Question, BackgroundPlace.Result]),
  ],
  [equals(TestType.Poll), always([BackgroundPlace.FirstPage, BackgroundPlace.Question])],
  [T, always([])],
]);

type Props = {
  backgroundPlace: BackgroundPlace[];
  className?: string;
  testType: TestType;
};

export const BackgroundPlaces: FC<Props> = ({ backgroundPlace, className, testType }) => (
  <Form.Item shouldUpdate name="backgroundPlace" valuePropName="checked" noStyle>
    <Checkbox.Group
      defaultValue={backgroundPlace}
      rootClassName={classNames(styles.checkboxGroup, className)}
      options={pipe(getBackgroundPlaceList, map(getBackgroundPlaceOption))(testType)}
    />
  </Form.Item>
);
