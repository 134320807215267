import { createApi } from '@reduxjs/toolkit/query/react';
import {
  GetManyPermissionsQueryPrivate,
  GetManyPermissionsResponsePrivate,
  RoleResponsePrivate,
  UpdateRoleBodyPrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { createUrl } from '@/utils';

import { authGuardQuery } from './queries';
import { rolesApi } from './roles';

export const permissionsApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    getPermissionsList: build.query<
      GetManyPermissionsResponsePrivate,
      RelationsParams & Partial<GetManyPermissionsQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((permission) => ({
          id: permission.id,
          type: 'Permissions' as const,
        })) || []),
        { id: 'List', type: 'Permissions' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Permissions),
      }),
    }),
  }),
  reducerPath: 'permissionsApi',
  tagTypes: ['Permissions'],
});

export const { useGetPermissionsListQuery } = permissionsApi;
