import { TemplateType } from '@tests/types';
import React, { SVGAttributes } from 'react';

import { AchbdTemplate, DefaultTemplate } from '@/assets';

type TemplateTypeSelectItem = {
  icon: React.FC<SVGAttributes<SVGElement>>;
  label: string;
  value: TemplateType;
};

export const templateTypes: Array<TemplateTypeSelectItem> = [
  { icon: DefaultTemplate, label: `Основной`, value: TemplateType.Default },
  { icon: AchbdTemplate, label: 'АЧБД', value: TemplateType.Achbd },
];
