import { Form, Input } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

const { TextArea } = Input;

type Props = {
  captionMaxLength: number;
  formItemName?: string;
  id: number;
  inverted?: boolean;
  text: string;
  updateBlock: any;
};

export const SubtitleBlock: React.FC<Props> = ({
  captionMaxLength,
  formItemName = 'subtitle',
  id,
  inverted = false,
  text,
  updateBlock,
}) => {
  const [form] = Form.useForm();

  const [count, setCount] = useState(text?.length);

  const onValuesChange = useCallback(
    (value: Record<string, string>) => {
      const { [formItemName]: changedSubTitle } = value;
      setCount(changedSubTitle.length);
    },
    [formItemName],
  );

  return (
    <FormBlock
      testId={id}
      blockTitle="Подзаголовок"
      form={form}
      count={count}
      maxCount={captionMaxLength}
      initialValues={{ [formItemName]: text }}
      onValuesChange={onValuesChange}
      updateBlock={updateBlock}
      inverted={inverted}
    >
      <Form.Item name={formItemName}>
        <TextArea
          className={classNames(styles.input, inverted && styles.inverted)}
          autoSize
          rows={1}
          bordered={inverted}
          placeholder="Напишите подзаголовок"
          maxLength={captionMaxLength}
        />
      </Form.Item>
    </FormBlock>
  );
};
