import classNames from 'classnames';
import { FC, PropsWithChildren } from 'react';

import styles from './styles.module.scss';

interface Props {
  noPadding?: boolean;
  title: string;
}

export const StatsInfoBlock: FC<PropsWithChildren<Props>> = ({
  children,
  noPadding = false,
  title,
}) => (
  <div className={styles.container}>
    <div className={styles.title}>{title}</div>
    <div className={classNames(!noPadding && styles.content)}>{children}</div>
  </div>
);
