import React, { FC } from 'react';

import styles from './styles.module.scss';

interface Props {
  checked?: boolean;
  onChange?: (checked: boolean) => void;
  text?: string;
}

export const Switch: FC<Props> = ({ checked, onChange, text }) => (
  // eslint-disable-next-line jsx-a11y/label-has-associated-control
  <label className={styles.switchWrapper}>
    <input
      type="checkbox"
      className={styles.switchInput}
      checked={checked}
      onChange={(event) => onChange(event.target.checked)}
    />
    <div className={styles.switchContainer}>
      <div className={styles.switch}>
        <div className={styles.switchCircle} />
      </div>
      {text && <span className={styles.switchText}>{text}</span>}
    </div>
  </label>
);
