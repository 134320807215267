import { useEffect, useState } from 'react';

function getWindowDimensions(): { height: number; width: number } {
  const { innerHeight: height, innerWidth: width } = window;

  return {
    height,
    width,
  };
}

type WindowDimensions = {
  height: number;
  width: number;
};

export const useWindowDimensions = (): WindowDimensions => {
  const [windowDimensions, setWindowDimensions] = useState<WindowDimensions>(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
};
