import {
  MAX_LEN_ANSWER_CROSSWORD_TEXT,
  MAX_LEN_ANSWER_EXTENDED_TEST_TEXT,
  MAX_LEN_ANSWER_TEXT,
} from '../db/answer/consts';
import { TestType } from '../db/test/TestType';

export function getAnswerTextLengthByTestType(type: TestType): number {
  switch (type) {
    case TestType.Extended:
      return MAX_LEN_ANSWER_EXTENDED_TEST_TEXT;
    case TestType.Crossword:
      return MAX_LEN_ANSWER_CROSSWORD_TEXT;
    default:
      return MAX_LEN_ANSWER_TEXT;
  }
}
