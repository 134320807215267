import { Result } from 'antd';
import queryString from 'query-string';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Provider } from '@/constants';
import { useProvider } from '@/hooks';
import { useOAuthCallbackMutation } from '@/services';
import { isSuccessResult } from '@/utils';

type Params = {
  provider: Provider;
};

export const OAuthCallbackPage: FC = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [oAuthCallback] = useOAuthCallbackMutation();
  const { provider } = useParams<Params>();
  const oAuthProvider = useProvider(provider);

  useEffect(() => {
    const { code } = queryString.parse(window.location.search);
    if (oAuthProvider?.getCallbackUrl()) {
      oAuthCallback({
        code: code.toString(),
        provider,
        redirectUri: oAuthProvider?.getCallbackUrl(),
      })
        .then((result: any) => {
          if (!isSuccessResult(result)) {
            setError(result.error);
          } else {
            navigate('/');
          }
        })
        .catch((e: any) => {
          setError(e);
        });
    }
  }, [oAuthProvider]);

  if (error) {
    console.error(error);

    return (
      <Result status="error" title="Ошибка авторизации">
        {error.data?.message || error.toString()}
      </Result>
    );
  }

  return <Result status="info" title="Идет авторизация через SSO..." />;
};
