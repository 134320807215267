import queryString from 'query-string';
import { anyPass, isEmpty, isNil, reject } from 'ramda';
import { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { filterSelector } from '@/selectors';
import { resetFilters as resetFiltersAction, setFilter as setFilterAction } from '@/slices';

import { useQueryString } from './useQueryString';

const clearObject = reject(anyPass([isEmpty, isNil]));

export const useFilterPage = <Q extends Record<string, any>>() => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const query = useQueryString();

  const allFiltersSelector = useSelector(filterSelector);
  const allFilters = clearObject(allFiltersSelector);
  const path = useMemo(() => location.pathname, []);

  const filter = (allFilters[path] ?? {}) as Q;

  const setFilter = useCallback(
    (values: Partial<Q>) => {
      dispatch(
        setFilterAction({
          path,
          values,
        }),
      );

      navigate({
        pathname: path,
        search: queryString.stringify({ ...query, ...values }),
      });
    },
    [dispatch, path, query],
  );
  const resetFilter = useCallback(
    (values?: any) => {
      dispatch(
        resetFiltersAction({
          path,
          values,
        }),
      );

      navigate({
        pathname: path,
        search: queryString.stringify(values || {}),
      });
    },
    [dispatch, navigate, path],
  );

  return {
    filter,
    resetFilter,
    setFilter,
  };
};
