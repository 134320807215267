import {
  MAX_LEN_RESULT_CROSSWORD_MEDIA_CAPTION,
  MAX_LEN_RESULT_MEDIA_CAPTION,
} from '../db/result/consts';
import { TestType } from '../db/test/TestType';

export function getResultMediaCaptionLengthByTestType(type: TestType): number {
  if (type === TestType.Crossword) {
    return MAX_LEN_RESULT_CROSSWORD_MEDIA_CAPTION;
  } else {
    return MAX_LEN_RESULT_MEDIA_CAPTION;
  }
}
