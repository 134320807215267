import { Form, Input } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { useGetValidationError } from '@/hooks';

import { FormBlock } from '../../containers/Blocks/Wrappers';
import styles from './styles.module.scss';

const { TextArea } = Input;

type Props = {
  formItemName?: string;
  id: number;
  inverted?: boolean;
  maxLength?: number;
  placeholder?: string;
  rows?: number;
  text: string;
  title?: string;
  updateBlock: any;
};

export const TextAreaBlock: React.FC<Props> = ({
  formItemName = 'title',
  id,
  inverted = false,
  maxLength = 140,
  placeholder = 'Напишите заголовок',
  rows = 1,
  text,
  title = 'Заголовок',
  updateBlock,
}) => {
  const [form] = Form.useForm();

  const [count, setCount] = useState(text?.length);

  const titleError = useGetValidationError({ field: formItemName, targetId: id });

  const onValuesChange = useCallback(
    (value: Record<string, string>) => {
      const { [formItemName]: changedTitle } = value;
      setCount(changedTitle.length);
    },
    [formItemName],
  );

  return (
    <FormBlock
      testId={id}
      blockTitle={title}
      form={form}
      count={count}
      maxCount={maxLength}
      initialValues={{ [formItemName]: text }}
      onValuesChange={onValuesChange}
      updateBlock={updateBlock}
      inverted={inverted}
    >
      <Form.Item
        name={formItemName}
        validateStatus={titleError && 'error'}
        help={titleError?.message}
      >
        <TextArea
          className={classNames(styles.input, inverted && styles.inverted)}
          autoSize
          rows={rows}
          variant={inverted ? 'filled' : 'borderless'}
          placeholder={placeholder}
          maxLength={maxLength}
        />
      </Form.Item>
    </FormBlock>
  );
};
