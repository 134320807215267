import { EditOutlined } from '@ant-design/icons';
import {
  EmployeeResponsePrivate,
  GetManyEmployeesResponsePrivate,
  GetManyRolesResponsePrivate,
  RoleResponsePrivate,
} from '@tests/types';
import { Avatar, Button, Col, Row, Space, Spin, Typography } from 'antd';
import classNames from 'classnames';
import React from 'react';

import { TestoLogo, User } from '@/assets';
import { Badge, InfiniteScroll } from '@/components';
import { getTextColor, getTextStatus, isDefined } from '@/utils';

import styles from './styles.module.scss';

type EmployeeListProps = {
  fetchData: () => Promise<void>;
  handleShowEditor: (item: EmployeeResponsePrivate<string> | RoleResponsePrivate<string>) => void;
  isFetching: boolean;
  listItems: any;
  listItemsData: GetManyEmployeesResponsePrivate | GetManyRolesResponsePrivate;
  listType: 'employees' | 'roles';
  page: number;
};

type UserRowProps = {
  onEditClick?: (user: EmployeeResponsePrivate<string>) => void;
  user: EmployeeResponsePrivate<string>;
};

type RoleRowProps = {
  onEditClick?: (role: RoleResponsePrivate<string>) => void;
  role: RoleResponsePrivate<string>;
  status: string;
};

const RoleFieldsRow = () => (
  <Row align="middle" justify="space-between" className={styles.fieldsRow}>
    <Col span={4}>
      <Typography.Text type="secondary">Роль</Typography.Text>
    </Col>
    <Col span={18}>
      <Typography.Text type="secondary">Статус</Typography.Text>
    </Col>
    <Col span={1} />
  </Row>
);

const UserFieldsRow = () => (
  <Row align="middle" justify="space-between" className={styles.fieldsRow}>
    <Col span={7}>
      <Typography.Text type="secondary">ФИО</Typography.Text>
    </Col>
    <Col span={4}>
      <Typography.Text type="secondary">ID</Typography.Text>
    </Col>
    <Col span={4}>
      <Typography.Text type="secondary">Статус</Typography.Text>
    </Col>
    <Col span={4}>
      <Typography.Text type="secondary">Проекты</Typography.Text>
    </Col>
    <Col span={1} />
  </Row>
);

const RoleRow: React.FC<RoleRowProps> = ({ onEditClick, role, status }) => {
  const handleEditClick = () => {
    onEditClick(role);
  };

  return (
    <Row align="middle" justify="space-between" className={styles.contentRow}>
      <Col span={4}>
        <Typography.Text className={styles[getTextColor(role?.name)]}>{role?.name}</Typography.Text>
      </Col>
      <Col span={18}>
        <Typography.Text className={styles[getTextColor(status)]}>
          {getTextStatus(status)}
        </Typography.Text>
      </Col>
      <Col span={1}>
        <Button
          className={styles.editBtn}
          icon={<EditOutlined />}
          type="default"
          size="middle"
          onClick={handleEditClick}
        />
      </Col>
    </Row>
  );
};

const UserRow: React.FC<UserRowProps> = ({ onEditClick, user }) => {
  const handleEditClick = () => {
    onEditClick(user);
  };

  return (
    <Row align="middle" justify="space-between" className={styles.contentRow}>
      <Col span={7}>
        <Row gutter={[8, 8]}>
          <Col>
            <Avatar
              src={user?.avatar?.url}
              className={classNames(styles.avatarWrapper, !user?.avatar && styles.avatar)}
            >
              {!user?.avatar && <User />}
            </Avatar>
          </Col>
          <Col>
            <Typography.Text>{user.name}</Typography.Text>
            <br />
            <Typography.Text type="secondary" className={styles[getTextColor(user?.role?.name)]}>
              {user?.role?.name}
            </Typography.Text>
          </Col>
        </Row>
      </Col>
      <Col span={4}>
        <Typography.Text>{user?.id}</Typography.Text>
      </Col>
      <Col span={4}>
        <Typography.Text type="secondary" className={styles[getTextColor(user?.status)]}>
          {getTextStatus(user?.status)}
        </Typography.Text>
      </Col>
      <Col span={4}>
        <Space size={10} wrap>
          {user?.projects?.map((project) => (
            <Badge className="project">
              {project?.logo?.url ? (
                <img height={12} src={project?.logo?.url} alt={project.alias} />
              ) : (
                <TestoLogo style={{ height: '12px' }} />
              )}
            </Badge>
          ))}
        </Space>
      </Col>
      <Col span={1}>
        <Button
          className={styles.editBtn}
          icon={<EditOutlined />}
          type="default"
          size="middle"
          onClick={handleEditClick}
        />
      </Col>
    </Row>
  );
};

export const DataList: React.FC<EmployeeListProps> = ({
  fetchData,
  handleShowEditor,
  isFetching,
  listItems,
  listItemsData,
  listType,
  page,
}) => {
  if (page === 0 && isFetching) {
    return (
      <Spin
        size="large"
        style={{
          padding: '10px',
          width: '100%',
        }}
      />
    );
  }
  if (listItems?.length === 0) {
    return (
      <Typography.Text className={styles.stub}>
        По вашему запросу ничего не найдено.
      </Typography.Text>
    );
  }

  return (
    <InfiniteScroll
      hasMore={isDefined(listItemsData) && listItemsData?.count > listItems?.length}
      isFetching={isFetching}
      fetchData={fetchData}
    >
      {listType === 'roles' ? (
        <>
          <RoleFieldsRow />
          {listItems?.map((role: RoleResponsePrivate<string>) => (
            <RoleRow role={role} status="active" onEditClick={handleShowEditor} />
          ))}
        </>
      ) : (
        <>
          <UserFieldsRow />
          {listItems?.map((user: EmployeeResponsePrivate<string>) => (
            <UserRow user={user} onEditClick={handleShowEditor} />
          ))}
        </>
      )}
    </InfiniteScroll>
  );
};
