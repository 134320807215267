import { createSlice } from '@reduxjs/toolkit';

import { themesAdapter } from '@/adapters';
import { themesApi } from '@/services';

interface State {
  count: number;
  lastRequestId?: string;
}

export const themesSlice = createSlice({
  extraReducers: (build) => {
    build.addMatcher(themesApi.endpoints.getThemesList.matchFulfilled, (state, action) => {
      if (state.lastRequestId === action.meta.requestId) {
        themesAdapter.upsertMany(state, action.payload.results);
        state.count = action.payload.count;
      }
    });
  },
  initialState: themesAdapter.getInitialState<State>({
    count: 0,
  }),
  name: 'themes',
  reducers: {},
});

export const themesReducer = themesSlice.reducer;
