import { createApi } from '@reduxjs/toolkit/query/react';
import {
  AnswerResponsePrivate,
  CreateAnswerBodyPrivate,
  GetManyAnswersQueryPrivate,
  GetManyAnswersResponsePrivate,
  UpdateAnswerBodyPrivate,
} from '@tests/types';

import { ApiPath } from '@/constants';
import { testsApi } from '@/services';
import { authGuardQuery } from '@/services/api/queries';
import { createUrl } from '@/utils';

export const answersApi = createApi({
  baseQuery: authGuardQuery,
  endpoints: (build) => ({
    createAnswer: build.mutation<AnswerResponsePrivate, CreateAnswerBodyPrivate>({
      invalidatesTags: [{ id: 'List', type: 'Answers' }],
      query: (data) => ({
        data,
        method: 'POST',
        url: createUrl(ApiPath.Answers),
      }),
    }),
    deleteAnswer: build.mutation<void, number>({
      invalidatesTags: [{ id: 'List', type: 'Answers' }],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(testsApi.util.invalidateTags(['Tests']));
        });
      },
      query: (id) => ({
        method: 'DELETE',
        url: createUrl(ApiPath.Answers, id),
      }),
    }),
    getAnswerById: build.query<AnswerResponsePrivate, RelationsParams & { id: number }>({
      providesTags: (test) => [{ id: test?.id, type: 'Answers' }],
      query: ({ id, ...params }) => ({
        method: 'GET',
        params: {
          ...params,
        },
        url: createUrl(ApiPath.Answers, id),
      }),
    }),
    getAnswersList: build.query<
      GetManyAnswersResponsePrivate,
      RelationsParams & Partial<GetManyAnswersQueryPrivate>
    >({
      providesTags: (result) => [
        ...(result?.results?.map((questionAnswer) => ({
          id: questionAnswer.id,
          type: 'Answers' as const,
        })) || []),
        { id: 'List', type: 'Answers' },
      ],
      query: (params) => ({
        method: 'GET',
        params: {
          order: '+sortOrder',
          ...params,
        },
        url: createUrl(ApiPath.Answers),
      }),
    }),
    updateAnswer: build.mutation<AnswerResponsePrivate, UpdateAnswerBodyPrivate & { id: number }>({
      invalidatesTags: (result) => [{ id: result?.id, type: 'Answers' }],
      onQueryStarted: (arg, api) => {
        api.queryFulfilled.then(() => {
          api.dispatch(testsApi.util.invalidateTags(['Tests']));
        });
      },
      query: ({ id, ...data }) => ({
        data,
        method: 'PATCH',
        url: createUrl(ApiPath.Answers, id),
      }),
    }),
  }),
  reducerPath: 'answersApi',
  tagTypes: ['Answers'],
});

export const {
  useCreateAnswerMutation,
  useDeleteAnswerMutation,
  useGetAnswerByIdQuery,
  useGetAnswersListQuery,
  useUpdateAnswerMutation,
} = answersApi;
