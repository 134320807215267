import { MAX_LEN_QUESTION_TEXT } from '@tests/types';
import { Form, Input } from 'antd';
import React, { useCallback, useState } from 'react';

import { useGetValidationError } from '@/hooks';

import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

const { TextArea } = Input;

type Props = {
  id: number;
  text: string;
  updateBlock: any;
};

export const QuestionBlock: React.FC<Props> = ({ id, text, updateBlock }) => {
  const [form] = Form.useForm();

  const [count, setCount] = useState(text?.length);
  const questionError = useGetValidationError({ field: 'text', targetId: id });

  const onValuesChange = useCallback(({ text: changedText }: { text: string }) => {
    setCount(changedText.length);
  }, []);

  return (
    <FormBlock
      testId={id}
      blockTitle="Вопрос"
      form={form}
      count={count}
      maxCount={MAX_LEN_QUESTION_TEXT}
      initialValues={{ text }}
      onValuesChange={onValuesChange}
      updateBlock={updateBlock}
    >
      <Form.Item
        name="text"
        validateStatus={questionError && 'error'}
        help={questionError?.message}
      >
        <TextArea
          className={styles.input}
          autoSize
          rows={1}
          bordered={false}
          maxLength={MAX_LEN_QUESTION_TEXT}
          placeholder="Напишите вопрос"
        />
      </Form.Item>
    </FormBlock>
  );
};
