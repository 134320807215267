import { CloseOutlined } from '@ant-design/icons';
import { DatePicker } from 'antd';
import classNames from 'classnames';
import React, { Dispatch } from 'react';

import styles from './styles.module.scss';

type Props = {
  onChange?: Dispatch<any>;
  value?: any;
};

const { RangePicker } = DatePicker;

export const DateFilter: React.FC<Props> = ({ onChange, value }) => (
  <RangePicker
    size="large"
    placeholder={['Выбрать', '']}
    className={classNames(styles.selector, value?.length === 2 && styles.filled)}
    onChange={onChange}
    separator={value?.length === 2 ? <div>-</div> : ''}
    clearIcon={<CloseOutlined />}
    inputReadOnly
    value={value}
    popupClassName={styles.picker}
  />
);
