import { Player } from '@lottiefiles/react-lottie-player';
import { MAX_LEN_QUESTION_TEXT, TestType } from '@tests/types';
import React from 'react';

import { Loader } from '@/assets';
import { DropType } from '@/constants';
import { AnswersBlock, ImageBlock, SliderBlock } from '@/containers/Blocks';
import { useGetQuestionByIdQuery, useUpdateQuestionMutation } from '@/services';

import { TextAreaBlock } from '../../components';
import styles from './styles.module.scss';

type Props = {
  questionId: number;
  testId: number;
  testType: TestType;
};

export const Question: React.FC<Props> = ({ questionId, testId, testType }) => {
  const { data: question, isLoading } = useGetQuestionByIdQuery(
    { id: questionId, relations: ['media'] },
    { refetchOnMountOrArgChange: true, skip: !questionId },
  );

  const [updateBlock] = useUpdateQuestionMutation();

  if (!question) {
    if (isLoading) {
      return <Player autoplay loop src={Loader} className={styles.loader} />;
    }

    return <div>Нет вопроса!</div>;
  }

  const { id, media, mediaCaption, mediaId, text, timeout } = question;

  return (
    <div className={styles.container}>
      <SliderBlock
        id={id}
        updateBlock={updateBlock}
        value={timeout}
        formItemName="timeout"
        blockTitle="Таймер на вопросе"
        min={0}
        max={60}
      />
      <TextAreaBlock
        text={text}
        id={id}
        formItemName="text"
        maxLength={MAX_LEN_QUESTION_TEXT}
        updateBlock={updateBlock}
        title="Вопрос"
        placeholder="Напишите вопрос"
      />
      <ImageBlock
        id={id}
        testId={testId}
        updateBlock={updateBlock}
        backgroundImageId={mediaId}
        backgroundImage={media}
        caption={mediaCaption}
        testType={testType}
        backgroundFormName="mediaId"
        title="Медиа к вопросу"
        withCaption
        dropType={DropType.All}
        mediaCaptionMaxLength={100}
      />
      <AnswersBlock
        testId={testId}
        question={question}
        updateBlock={updateBlock}
        testType={testType}
      />
    </div>
  );
};
