import { message as antMessage, MessageArgsProps } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { CheckAlert, ErrorAlert } from '@/assets';

import styles from './styles.module.scss';

interface Props {
  description?: string;
  duration?: number;
  title?: string;
  type: MessageArgsProps['type'];
}

const getIcon = cond([
  [equals('success'), always(CheckAlert)],
  [equals('error'), always(ErrorAlert)],
  [T, always(null)],
]);

export const message = async (props: Props) => {
  const Icon = getIcon(props.type);
  const content = (
    <div className={styles.content}>
      {props.title && <div className={classNames(styles.text, styles.title)}>{props.title}</div>}
      {props.description && <div className={styles.text}>{props.description}</div>}
    </div>
  );

  await antMessage[props.type]({
    className: classNames(styles.message, styles[props.type]),
    content,
    duration: props.duration,

    icon: (
      <div className={styles.iconWrapper}>
        <Icon className={styles.icon} />
      </div>
    ),
  });
};
