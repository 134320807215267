import { TestStatus } from '@tests/types';
import { Tooltip } from 'antd';
import { always, cond, equals, T } from 'ramda';
import React from 'react';

import { Draft, Published, Unpublished } from '@/assets';

import styles from './styles.module.scss';

type Props = {
  status: TestStatus;
};

const getStatus = cond<[string], Nullable<JSX.Element>>([
  [equals(TestStatus.Draft), always(<Draft />)],
  [equals(TestStatus.Published), always(<Published />)],
  [equals(TestStatus.Unpublished), always(<Unpublished />)],
  [T, always(null)],
]);

const getTooltip = cond<[string], Nullable<string>>([
  [equals(TestStatus.Draft), always('Черновик')],
  [equals(TestStatus.Published), always('Опубликовано')],
  [equals(TestStatus.Unpublished), always('Снято с публикации')],
  [T, always(null)],
]);

export const Status: React.FC<Props> = ({ status }) => (
  <div className={styles.tooltip}>
    <Tooltip title={getTooltip(status)} color="var(--color-grey-lvl2)">
      {getStatus(status)}
    </Tooltip>
  </div>
);
