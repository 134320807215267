import { useSelector } from 'react-redux';

import { validationErrorsTestsSelector } from '@/selectors';

export type ValidatingBlockProps = {
  field: string;
  targetId: number;
};

export const useGetValidationError = ({ field, targetId }: ValidatingBlockProps) => {
  const validationErrors = useSelector(validationErrorsTestsSelector);

  return validationErrors?.find((e) => e.field === field && e.targetId === targetId);
};
