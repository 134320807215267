import { Form, Input } from 'antd';
import classNames from 'classnames';
import React, { useCallback, useState } from 'react';

import { useGetValidationError } from '@/hooks';

import { FormBlock } from '../Wrappers/FormBlock';
import styles from './styles.module.scss';

const { TextArea } = Input;

type Props = {
  formItemName?: string;
  id: number;
  inverted?: boolean;
  text: string;
  updateBlock: any;
};

export const TitleBlock: React.FC<Props> = ({
  formItemName = 'title',
  id,
  inverted = false,
  text,
  updateBlock,
}) => {
  const [form] = Form.useForm();

  const [count, setCount] = useState(text?.length);

  const titleError = useGetValidationError({ field: 'title', targetId: id });

  const onValuesChange = useCallback(
    (value: Record<string, string>) => {
      const { [formItemName]: changedTitle } = value;
      setCount(changedTitle.length);
    },
    [formItemName],
  );

  return (
    <FormBlock
      testId={id}
      blockTitle="Заголовок"
      form={form}
      count={count}
      maxCount={140}
      initialValues={{ [formItemName]: text }}
      onValuesChange={onValuesChange}
      updateBlock={updateBlock}
      inverted={inverted}
    >
      <Form.Item
        name={formItemName}
        validateStatus={titleError && 'error'}
        help={titleError?.message}
      >
        <TextArea
          className={classNames(styles.input, inverted && styles.inverted)}
          autoSize
          rows={1}
          bordered={inverted}
          placeholder="Напишите заголовок"
          maxLength={140}
        />
      </Form.Item>
    </FormBlock>
  );
};
