import { Provider } from '@/constants';

import { useSSOProvider } from './useSSOProvider';

export const useProvider = (provider: Provider) => {
  const ssoProvider = useSSOProvider();

  switch (provider) {
    case Provider.SSO: {
      return ssoProvider;
    }
    default: {
      return null;
    }
  }
};
