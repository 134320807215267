import classNames from 'classnames';
import React, { PropsWithChildren } from 'react';

import styles from './styles.module.scss';

type Props = {
  className?: string;
};

export const Badge: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, className } = props;

  return <div className={classNames(styles.badge, styles[className])}>{children}</div>;
};
