import { TestResponsePrivate, TestType } from '@tests/types';
import { always, cond, equals } from 'ramda';
import React from 'react';

import { useUpdateTestMutation } from '@/services';
import { isCrossword, isExtendedTest, isPoll, isQuiz, isTest } from '@/utils';

import { TextAreaBlock } from '../../components';
import { DropType } from '../../constants';
import { ImageBlock, SwitcherBlock } from '../Blocks';
import styles from './styles.module.scss';

type Props = {
  test: TestResponsePrivate;
};

const getInfoText = cond([
  [equals(TestType.Quiz), always('Отображать баллы (для всех диапазонов)')],
  [equals(TestType.Extended), always('Отображать баллы (для всех результатов)')],
  [equals(TestType.Test), always('Отображать баллы (для всех диапазонов)')],
  [equals(TestType.Poll), always('Скрывать процент выбравших ответ (для всех вопросов)')],
]);

export const GeneralSettings: React.FC<Props> = ({ test }) => {
  const {
    backgroundImage,
    backgroundImageId,
    backgroundPlace,
    crosswordBackgroundImage,
    crosswordBackgroundImageId,
    hideResultPercents,
    id,
    showPointsForAllRanges,
    subtitle,
    title,
    type,
  } = test;

  const [updateBlock] = useUpdateTestMutation();

  const isShowSwitcher = isQuiz(type) || isTest(type) || isExtendedTest(type) || isPoll(type);

  return (
    <div className={styles.container}>
      {isShowSwitcher && (
        <SwitcherBlock
          checked={isPoll(type) ? hideResultPercents : showPointsForAllRanges}
          formItemName={isPoll(type) ? 'hideResultPercents' : 'showPointsForAllRanges'}
          infoText={getInfoText(type)}
          id={id}
          updateBlock={updateBlock}
        />
      )}
      <TextAreaBlock
        text={title}
        id={id}
        formItemName="title"
        updateBlock={updateBlock}
        title="Заголовок"
        placeholder="Напишите заголовок"
      />
      <TextAreaBlock
        text={subtitle}
        id={id}
        formItemName="subtitle"
        updateBlock={updateBlock}
        title="Подзаголовок"
        maxLength={270}
        placeholder="Напишите подзаголовок"
      />

      <ImageBlock
        id={id}
        testId={id}
        updateBlock={updateBlock}
        backgroundImageId={backgroundImageId}
        backgroundImage={backgroundImage}
        testType={test.type}
        dropType={DropType.Image}
        backgroundPlace={backgroundPlace}
      />

      {isCrossword(type) && (
        <ImageBlock
          id={id}
          testId={id}
          dropType={DropType.Image}
          updateBlock={updateBlock}
          title="Фон игрового поля"
          dropzonePlaceholder="Добавьте фон игрового поля"
          backgroundFormName="crosswordBackgroundImageId"
          backgroundImageId={crosswordBackgroundImageId}
          backgroundImage={crosswordBackgroundImage}
          testType={test.type}
        />
      )}
    </div>
  );
};
