import queryString, { StringifyOptions } from 'query-string';

export const stringifyQueryString = (options?: StringifyOptions) => (params: Record<string, any>) =>
  queryString.stringify(params, options);

export function isSuccessResult<T, E = any>(
  result: { data: T } | { error: E },
): result is { data: T } {
  return (<{ data: T }>result).data !== undefined;
}
