import classNames from 'classnames';
import React, { FC, PropsWithChildren, ReactNode } from 'react';

import { ButtonSize, ButtonType } from '@/types';

import styles from './styles.module.scss';

interface Props {
  afterIcon?: ReactNode;
  beforeIcon?: ReactNode;
  className?: string;
  disabled?: boolean;
  flex?: boolean;
  onClick?: () => void;
  rounded?: boolean;
  size?: ButtonSize;
  square?: boolean;
  type?: ButtonType;
}

export const Button: FC<PropsWithChildren<Props>> = ({
  afterIcon,
  beforeIcon,
  children,
  className,
  disabled,
  flex,
  onClick,
  rounded,
  size = ButtonSize.Regular,
  square,
  type = ButtonType.Text,
}) => (
  <button
    type="button"
    disabled={disabled}
    className={classNames(
      styles.button,
      styles[type],
      styles[size],
      className,
      (!children || square) && styles.square,
      rounded && styles.rounded,
      flex && styles.flex,
      beforeIcon && afterIcon && styles.doubleIcon,
    )}
    onClick={onClick}
  >
    {beforeIcon && <div className={classNames(styles.icon, styles.beforeIcon)}>{beforeIcon}</div>}
    {children}
    {afterIcon && <div className={classNames(styles.icon, styles.afterIcon)}>{afterIcon}</div>}
  </button>
);
