import { ProjectResponsePrivate } from '@tests/types';
import { Button, Card as CardItem, Col, Row } from 'antd';
import React from 'react';

import { TestoLogo } from '@/assets';
import { Badge } from '@/components';

import styles from './styles.module.scss';

interface Props {
  onProjectEditClick: (id: number) => void;
  project: ProjectResponsePrivate;
}

export const ProjectCard: React.FC<Props> = (props) => {
  const {
    onProjectEditClick,
    project: { id, logo, name },
  } = props;

  const onEditClick = async () => {
    await onProjectEditClick(id);
  };

  return (
    <CardItem bordered={false} className={styles.card} bodyStyle={{ padding: 0 }}>
      <div className={styles.wrapper}>
        <Row>
          <Col>
            <Badge className="project">{name}</Badge>
          </Col>
        </Row>
        <Row justify="center" align="middle" className={styles.logoWrapper}>
          {logo ? (
            <img height={30} src={logo?.url} alt="name" />
          ) : (
            <TestoLogo style={{ height: '30px' }} />
          )}
        </Row>
        <Row>
          <Col span={24}>
            <Button className={styles.button} onClick={onEditClick}>
              Редактировать
            </Button>
          </Col>
        </Row>
      </div>
    </CardItem>
  );
};
