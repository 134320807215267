import React from 'react';

interface Props {
  isVisible: boolean;
}

export const Visible: React.FC<React.PropsWithChildren<Props>> = (props) => {
  const { isVisible = false } = props;
  const { children = null } = props;

  return <>{isVisible ? children : null}</>;
};
