export enum DropType {
  All = 'all',
  Gif = 'gif',
  Image = 'image',
  Video = 'video',
}

export enum DropZoneType {
  Compact = 'compact',
  Regular = 'regular',
}
