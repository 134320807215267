export const isArrayBuffer = (data: any): data is ArrayBuffer => data instanceof ArrayBuffer;

export const getArrayBuffer = (file: File | Blob): Promise<ArrayBuffer> =>
  new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      resolve(event.target?.result as ArrayBuffer);
    };

    fileReader.onerror = (event) => {
      console.error(event);
      reject(event);
    };

    fileReader.readAsArrayBuffer(file);
  });
