import { TestValidationError, TestValidationErrorTarget } from '@tests/types';

type Props = {
  field: string;
  targetId: number;
  validationErrors: TestValidationError[];
};

export const filterValidationErrors = ({ field, targetId, validationErrors }: Props) =>
  validationErrors?.filter((e) => !(e.field === field && e.targetId === targetId));

export const getValidationErrorsByTarget = (
  errors: TestValidationError[],
  target: TestValidationErrorTarget,
) => errors?.filter((error) => error.target === target);

export const getValidationErrorsByTargetAndField = (
  errors: TestValidationError[],
  target: TestValidationErrorTarget,
  field: string,
) => errors?.filter((error) => error.target === target && error.field === field);

export const getValidationErrorsByTargetAndId = (
  errors: TestValidationError[],
  target: TestValidationErrorTarget,
  targetId: number,
) => errors?.filter((error) => error.target === target && error.targetId === targetId);
