import { createSlice } from '@reduxjs/toolkit';
import { TestValidationError } from '@tests/types';
import { compose, equals, not } from 'ramda';

import { testsAdapter } from '@/adapters';
import { testsApi } from '@/services';

interface State {
  count: number;
  lastRequestId?: string;
  showPointsForAllRanges?: boolean;
  testsUpdating: (number | string)[];
  validationErrors?: TestValidationError[];
}

export const testsSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(testsApi.endpoints.getTestsList.matchPending, (state, action) => {
        if (
          (action.meta.arg?.originalArgs?.offset === 0 || !action.meta.arg?.originalArgs?.offset) &&
          action.meta.arg.subscribe
        ) {
          testsAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(testsApi.endpoints.getTestsList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          testsAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
        }
      })
      .addMatcher(testsApi.endpoints.publishTest.matchFulfilled, (state, action) => {
        testsAdapter.updateOne(state, {
          changes: action.payload,
          id: action.payload.id,
        });
      })
      .addMatcher(testsApi.endpoints.unpublishTest.matchFulfilled, (state, action) => {
        testsAdapter.updateOne(state, {
          changes: action.payload,
          id: action.payload.id,
        });
      })
      .addMatcher(testsApi.endpoints.deleteTest.matchFulfilled, (state) => {
        testsAdapter.removeAll(state);
      })
      .addMatcher(testsApi.endpoints.updateManyTests.matchFulfilled, (state) => {
        testsAdapter.removeAll(state);
      })
      .addMatcher(testsApi.endpoints.updateTest.matchPending, (state, action) => {
        state.testsUpdating = [...state.testsUpdating, action.meta.arg.originalArgs.id];
      })
      .addMatcher(testsApi.endpoints.updateTest.matchRejected, (state, action) => {
        state.testsUpdating = state.testsUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs.id)),
        );
      })
      .addMatcher(testsApi.endpoints.updateTest.matchFulfilled, (state, action) => {
        state.testsUpdating = state.testsUpdating.filter(
          compose(not, equals(action.meta.arg.originalArgs.id)),
        );
        testsAdapter.updateOne(state, {
          changes: action.payload,
          id: action.payload.id,
        });
      });
  },
  initialState: testsAdapter.getInitialState<State>({
    count: 0,
    showPointsForAllRanges: true,
    testsUpdating: [],
    validationErrors: null,
  }),
  name: 'tests',
  reducers: {
    setValidationErrors(state, action) {
      state.validationErrors = action.payload;
    },
  },
});

export const { setValidationErrors } = testsSlice.actions;
export const testsReducer = testsSlice.reducer;
