import { CheckOutlined } from '@ant-design/icons';
import { TestResponsePrivate, TestType } from '@tests/types';
import { Button, Card as CardItem, Col, Divider, Row, Space } from 'antd';
import classNames from 'classnames';
import { always, cond, equals, T } from 'ramda';
import React, { useMemo } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import { Attachments, Link as LinkIcon, Stats, TestoLogo } from '@/assets';
import { Badge, DateChanged, Status } from '@/components';
import { RoutesPath } from '@/constants';

import { message } from '../../atoms';
import styles from './styles.module.scss';

interface Props {
  highlightMode: boolean;
  highlightedIds: number[];
  onHandleHighlight: (ids: number[]) => void;
  test: TestResponsePrivate;
}

const getTypeTitle = cond<[string], Nullable<string>>([
  [equals(TestType.Test), always('Тест')],
  [equals(TestType.Quiz), always('Квиз')],
  [equals(TestType.Extended), always('Расширенный тест')],
  [equals(TestType.Poll), always('Голосование')],
  [equals(TestType.Crossword), always('Кроссворд')],
  [T, always(null)],
]);

export const TestCard: React.FC<Props> = (props) => {
  const {
    test: { title, type, questionsCount, createdAt, updatedAt, projects = [], status, id },
    highlightMode,
    onHandleHighlight,
    highlightedIds,
  } = props;

  const navigate = useNavigate();

  const handleEditTestClick = (newTab = false) => {
    if (newTab) {
      window.open(`/test/${id}`, '_blank');
    } else {
      navigate(`/test/${id}`);
    }
  };

  const handleStatsTestClick = (newTab = false) => {
    if (newTab) {
      window.open(`/test/stats/${id}`, '_blank');
    } else {
      navigate(`/test/stats/${id}`);
    }
  };

  const handleCopyTestLinkClick = () => {
    navigator.clipboard
      .writeText(
        generatePath(`${window.location.origin}${RoutesPath.TestEdit}`, {
          idOrAlias: id.toString(10),
        }),
      )
      .then(() => message({ title: 'Ссылка на интерактив скопирована', type: 'success' }));
  };

  const isHighlighted = useMemo(
    () => highlightMode && highlightedIds.includes(id),
    [highlightMode, highlightedIds, id],
  );

  return (
    <CardItem
      bordered={false}
      className={classNames(styles.card, isHighlighted && styles.highlightedCard)}
      bodyStyle={{ padding: 0 }}
    >
      <Row gutter={[15, 15]} style={{ height: '100%' }} align="stretch">
        <Col span={24}>
          <Row justify="space-between">
            <Col>
              <Row gutter={[11, 11]}>
                <Badge className={type}>{getTypeTitle(type)}</Badge>
                <Col>
                  <Status status={status} />
                </Col>
              </Row>
            </Col>
            <Col>
              <Space size={[5, 5]}>
                <Attachments /> {questionsCount}
              </Space>
            </Col>
          </Row>
        </Col>
        <Col span={24}>
          <Row gutter={[10, 10]} style={{ height: '100%' }} align="stretch">
            <Col span={24}>
              <span className={styles.cardTitle}>{title}</span>
            </Col>
            <Col span={24}>
              <Row gutter={[5, 5]}>
                <Col span={24} className={styles.dates}>
                  <DateChanged date={createdAt} type="created" />
                </Col>
                <Col span={24} className={styles.dates}>
                  <DateChanged date={updatedAt} type="updated" />
                </Col>
              </Row>
            </Col>
            <Col span={24}>
              <Divider
                type="horizontal"
                style={{ borderColor: '#292B30', borderWidth: 1, margin: 0 }}
              />
            </Col>
            <Col span={24}>
              <Row gutter={[10, 10]} style={{ height: '100%' }} align="stretch">
                <Col span={24}>
                  <Space size={10} wrap>
                    {projects.map((project) => (
                      <Badge className="project">
                        {project?.logo?.url ? (
                          <img height={12} src={project?.logo?.url} alt={project.alias} />
                        ) : (
                          <TestoLogo style={{ height: '12px' }} />
                        )}
                      </Badge>
                    ))}
                  </Space>
                </Col>
                <Col span={24}>
                  {highlightMode ? (
                    <Row gutter={[5, 5]}>
                      <Col flex="auto">
                        <Button
                          className={styles.button}
                          // eslint-disable-next-line consistent-return
                          onMouseDown={(e) => {
                            if (e.button === 1) {
                              e.preventDefault();
                              e.stopPropagation();

                              return false;
                            }
                          }}
                          onMouseUp={(e) => {
                            if (isHighlighted) {
                              onHandleHighlight(highlightedIds.filter((hlId) => hlId !== id));
                            } else {
                              onHandleHighlight([...highlightedIds, id]);
                            }
                          }}
                        >
                          {isHighlighted ? <CheckOutlined /> : 'Выделить'}
                        </Button>
                      </Col>
                    </Row>
                  ) : (
                    <Row gutter={[5, 5]}>
                      <Col flex="auto">
                        <Button
                          className={styles.button}
                          // eslint-disable-next-line consistent-return
                          onMouseDown={(e) => {
                            if (e.button === 1) {
                              e.preventDefault();
                              e.stopPropagation();

                              return false;
                            }
                          }}
                          onMouseUp={(e) => {
                            handleEditTestClick(e?.nativeEvent?.which === 2);
                          }}
                        >
                          Редактировать
                        </Button>
                      </Col>
                      <Col>
                        <Button
                          className={styles.button}
                          // eslint-disable-next-line consistent-return
                          onMouseDown={(e) => {
                            if (e.button === 1) {
                              e.preventDefault();
                              e.stopPropagation();

                              return false;
                            }
                          }}
                          onMouseUp={(e) => {
                            handleStatsTestClick(e?.nativeEvent?.which === 2);
                          }}
                        >
                          <Stats />
                        </Button>
                      </Col>
                      <Col>
                        <Button className={styles.button} onClick={handleCopyTestLinkClick}>
                          <LinkIcon />
                        </Button>
                      </Col>
                    </Row>
                  )}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </CardItem>
  );
};
