import { DragOverlay } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Col, ColProps } from 'antd';
import React, { FC, PropsWithChildren } from 'react';

type Props = PropsWithChildren &
  Omit<ColProps, 'id'> & {
    childHandle?: boolean;
    disabled?: boolean;
    id: string | number;
  };

export const SortableItem: FC<Props> = ({
  childHandle = false,
  children,
  disabled = false,
  id,
  ...props
}) => {
  const { active, attributes, listeners, setNodeRef, transform, transition } = useSortable({
    disabled,
    id,
  });
  const style = {
    cursor: disabled ? 'default' : 'pointer',
    transform: CSS.Transform.toString(transform),
    transition,
  };
  const parentStyle = {
    opacity: active?.id === id ? '0.2' : '',
    transform: CSS.Transform.toString(transform),
    transition,
  };
  if (childHandle) {
    return (
      <>
        <Col ref={setNodeRef} style={parentStyle} {...props}>
          {children}
        </Col>
        {active?.id === id && <DragOverlay adjustScale={false}>{children}</DragOverlay>}{' '}
      </>
    );
  }

  return (
    <Col ref={setNodeRef} style={style} {...attributes} {...listeners} {...props}>
      {children}
    </Col>
  );
};
