import { createDraftSafeSelector } from '@reduxjs/toolkit';
import { prop } from 'ramda';

import { backgroundsAdapter } from '@/adapters';

import { stateSelector } from './state';

export const backgroundsEntitySelector = backgroundsAdapter.getSelectors<RootState>(
  prop('backgrounds'),
);

export const backgroundsSelector = createDraftSafeSelector(
  stateSelector,
  prop<RootState['backgrounds']>('backgrounds'),
);
