import { format, intervalToDuration, isToday, isYesterday, parseISO } from 'date-fns';
import { formatWithOptions } from 'date-fns/fp';
import { ru } from 'date-fns/locale';
import { cond, descend, identity, isNil, lensIndex, pipe, sortWith, T, view } from 'ramda';

import { dateFormat } from '@/constants';

export const formatDate = pipe(
  parseISO,
  cond([
    [
      (date) => isToday(date),
      (value) => `Сегодня, ${formatWithOptions({ locale: ru }, 'HH:mm', value)}`,
    ],
    [
      (date) => isYesterday(date),
      (value) =>
        `Вчера, ${formatWithOptions({ locale: ru }, 'dd MMMM', value)}, ${formatWithOptions(
          { locale: ru },
          'HH:mm',
          value,
        )}`,
    ],
    [
      T,
      (value: Date) =>
        `${formatWithOptions({ locale: ru }, 'dd.MM.yyyy', value)}, ${formatWithOptions(
          { locale: ru },
          'HH:mm',
          value,
        )}`,
    ],
  ]),
);

export const formatFormDate = (date?: Date) => date && format(date, dateFormat);

export const getGreatDate = (...dates: string[]) =>
  pipe(sortWith<string>([descend(identity)]), view(lensIndex<string>(0)))(dates);

export const formatDuration = (milliseconds: number) => {
  if (isNil(milliseconds)) {
    return '';
  }

  const duration = intervalToDuration({ end: milliseconds, start: 0 });
  const { hours, minutes, seconds } = duration;

  let formattedDuration = '';

  if (hours > 0) {
    formattedDuration += `${hours} ч `;
  }
  if (minutes > 0) {
    formattedDuration += `${minutes} мин `;
  }
  if (seconds >= 0) {
    formattedDuration += `${seconds} с`;
  }

  return formattedDuration.trim();
};
