import { PlusOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import classNames from 'classnames';
import React from 'react';

import styles from './styles.module.scss';

type Props = {
  disabled?: boolean;
  onClick: () => void;
  text?: string;
};

export const AddButton: React.FC<Props> = ({ disabled, onClick, text = 'Добавить ответ' }) => (
  <Row
    justify="center"
    align="middle"
    gutter={[5, 5]}
    className={classNames(styles.button, disabled && styles.disabled)}
    onClick={onClick}
  >
    <Col>
      <PlusOutlined />
    </Col>
    <Col>{text}</Col>
  </Row>
);
