import { createSlice } from '@reduxjs/toolkit';
import { RoleResponsePrivate } from '@tests/types';

import { permissionsAdapter } from '@/adapters';
import { permissionsApi } from '@/services';

interface State {
  changes?: Partial<RoleResponsePrivate>[];
  count: number;
  lastRequestId?: string;
}

export const permissionsSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(permissionsApi.endpoints.getPermissionsList.matchPending, (state, action) => {
        if (action.meta.arg?.originalArgs?.offset === 0 && action.meta.arg.subscribe) {
          permissionsAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(permissionsApi.endpoints.getPermissionsList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          permissionsAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
          state.changes = [];
        }
      });
  },
  initialState: permissionsAdapter.getInitialState<State>({
    changes: [],
    count: 0,
  }),
  name: 'permissions',
  reducers: {},
});

export const permissionsReducer = permissionsSlice.reducer;
