import { createDraftSafeSelector } from '@reduxjs/toolkit';
import type { Employee, Role } from '@tests/types';
import { prop } from 'ramda';

import { stateSelector } from './state';

export const userStateSelector = createDraftSafeSelector(stateSelector, prop<Employee>('user'));

export const userSelector = createDraftSafeSelector(userStateSelector, prop<Employee>('user'));

export const isAuthenticatedSelector = createDraftSafeSelector(
  userStateSelector,
  prop<boolean>('isAuthenticated'),
);

export const userRoleSelector = createDraftSafeSelector(userSelector, prop<Role>('role'));
