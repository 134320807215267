import { createSlice } from '@reduxjs/toolkit';
import { ProjectResponsePrivate } from '@tests/types';

import { projectsApi } from '@/services';

import { projectsAdapter } from '../adapters/projects';

interface State {
  changes?: Partial<ProjectResponsePrivate>[];
  count: number;
  lastRequestId?: string;
  projectsAdapting: number[];
}

export const projectsSlice = createSlice({
  extraReducers: (build) => {
    build
      .addMatcher(projectsApi.endpoints.getProjectsList.matchPending, (state, action) => {
        if (action.meta.arg.subscribe) {
          projectsAdapter.removeAll(state);
        }
        state.lastRequestId = action.meta.requestId;
      })
      .addMatcher(projectsApi.endpoints.getProjectsList.matchFulfilled, (state, action) => {
        if (state.lastRequestId === action.meta.requestId) {
          projectsAdapter.upsertMany(state, action.payload.results);
          state.count = action.payload.count;
          state.changes = [];
        }
      });
  },
  initialState: projectsAdapter.getInitialState<State>({
    changes: [],
    count: 0,
    projectsAdapting: [],
  }),
  name: 'projects',
  reducers: {},
});

export const projectsReducer = projectsSlice.reducer;
