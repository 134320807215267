import { FileStatus } from '@tests/types';
import classNames from 'classnames';
import { inc } from 'ramda';
import { FC, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { backgroundsEntitySelector } from '@/selectors';

import { Button } from '../../atoms';
import { useGetBackgroundsQuery } from '../../services';
import { ButtonType } from '../../types';
import { isDefined } from '../../utils';
import { DrawerWrapper } from '../DrawerWrapper';
import { InfiniteScroll } from '../InfiniteScroll';
import { InteractiveImage } from '../InteractiveImage';
import styles from './styles.module.scss';

interface Props {
  onClose: () => void;
  onSetImage: (id: number) => void;
  testId: number;
}

export const InteractiveImagesModal: FC<Props> = ({ onClose, onSetImage, testId }) => {
  const [page, setPage] = useState(0);

  const { data, isFetching } = useGetBackgroundsQuery(
    { id: testId, isModal: true, offset: page * 30, order: '-id' },
    { refetchOnMountOrArgChange: true, skip: !testId },
  );

  const images = useSelector(backgroundsEntitySelector.selectAll);

  const filteredImages = useMemo(
    () => images.filter((image) => image.status === FileStatus.Complete),
    [images],
  );
  const [selectedImage, setSelectedImage] = useState(null);

  const fetchData = async () => {
    setPage(inc);
  };

  useEffect(() => {
    document.body.style.width = '100%';

    return () => {
      document.body.style.width = '';
    };
  }, []);

  return (
    <DrawerWrapper
      desktopModal
      handleClose={onClose}
      open
      title="Ранее загруженные"
      modalClassName={styles.modal}
      mobileDimension={768}
    >
      <div className={styles.body}>
        <div className={styles.content}>
          <InfiniteScroll
            hasMore={isDefined(data) && data?.count > images?.length}
            isFetching={isFetching}
            fetchData={fetchData}
          >
            <div className={styles.grid}>
              {filteredImages.map((image) => (
                <div
                  key={image.id}
                  className={classNames(
                    styles.image,
                    selectedImage === image.id && styles.selected,
                  )}
                  onClick={() => {
                    if (selectedImage === image.id) {
                      setSelectedImage(null);
                    } else {
                      setSelectedImage(image.id);
                    }
                  }}
                >
                  <InteractiveImage
                    large
                    alt={image.name}
                    id={image.id}
                    url={image.url}
                    testId={testId}
                  />
                </div>
              ))}
            </div>
          </InfiniteScroll>
        </div>

        <div className={styles.buttons}>
          <Button
            type={ButtonType.Secondary}
            onClick={onClose}
            className={classNames(styles.button, styles.cancel)}
          >
            Отменить
          </Button>
          <Button
            disabled={!selectedImage}
            type={ButtonType.Primary}
            onClick={() => onSetImage(selectedImage)}
            className={styles.button}
          >
            Добавить
          </Button>
        </div>
      </div>
    </DrawerWrapper>
  );
};
