import { Spin } from 'antd';
import React, { PropsWithChildren } from 'react';
import useInfiniteScroll from 'react-infinite-scroll-hook';

interface Props {
  fetchData(): Promise<void>;
  hasMore: boolean;
  isFetching: boolean;
}

export const InfiniteScroll: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, fetchData, hasMore = false, isFetching } = props;

  const [sentryRef] = useInfiniteScroll({
    delayInMs: 300,
    hasNextPage: hasMore,
    loading: isFetching,
    onLoadMore: fetchData,
    rootMargin: '0px 0px 400px 0px',
  });

  return (
    <div>
      {children}
      {(isFetching || hasMore) && (
        <div ref={sentryRef}>
          <Spin
            size="large"
            style={{
              padding: '10px',
              width: '100%',
            }}
          />
        </div>
      )}
    </div>
  );
};
