import {
  Permission,
  PermissionAction,
  PermissionResource,
  PermissionResponsePrivate,
} from '@tests/types';
import { always, cond, equals, T } from 'ramda';

import { AchbdLogo, FivePublicLogo, LifeLogo, MashLogo, SuperLogo, ZhiznLogo } from '@/assets';

export const getTextColor = cond([
  [equals('Супер админ'), always('superAdminColor')],
  [equals('Админ'), always('adminColor')],
  [equals('active'), always('statusActiveColor')],
  [equals('blocked'), always('statusInactiveColor')],
  [T, always('userColor')],
]);

export const getTextStatus = cond([
  [equals('active'), always('Активен')],
  [equals('blocked'), always('Неактивен')],
  [T, always(null)],
]);

export const getProject = cond<[string], Nullable<JSX.Element>>([
  [equals('mash'), always(<MashLogo />)],
  [equals('super'), always(<SuperLogo />)],
  [equals('life'), always(<LifeLogo />)],
  [equals('zhizn'), always(<ZhiznLogo />)],
  [equals('achbd'), always(<AchbdLogo />)],
  [equals('fivePublic'), always(<FivePublicLogo />)],
  [T, always(null)],
]);

export const STATUS_OPTIONS = [
  { label: 'Активен', value: 'active' },
  { label: 'Неактивен', value: 'blocked' },
];

export const handlePopupScroll = (
  event: React.UIEvent<HTMLDivElement>,
  fetchData: () => Promise<void>,
  hasMore: boolean,
): void => {
  const target = event.target as HTMLDivElement;
  if (target.scrollTop + target.offsetHeight === target.scrollHeight && hasMore) {
    fetchData();
  }
};

const makeLabel = cond([
  [equals('Create'), always('Создание')],
  [equals('Update'), always('Редактирование')],
  [equals('Delete'), always('Удаление')],
  [equals('Read'), always('Чтение')],
  [equals('Full'), always('Полный доступ')],
]);

export const groupByResource = <T extends Permission | PermissionResponsePrivate>(
  permissions: T[],
  resource: PermissionResource | null,
): { label: string; value: number }[] =>
  permissions
    ?.filter((p) => resource === null || p.resource === resource)
    ?.map((p) => ({ label: makeLabel(p.action), value: p.id }));
