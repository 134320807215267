import { Spin } from 'antd';
import React, { PropsWithChildren, useEffect, useState } from 'react';
import InfiniteScrollLib from 'react-infinite-scroll-component';

interface Props {
  fetchData(): Promise<void>;
  hasMore: boolean;
  length: number;
  offset: number;
}

export const InfiniteScrollComponent: React.FC<PropsWithChildren<Props>> = (props) => {
  const { children, fetchData, hasMore, length, offset } = props;
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (length > 0) {
      setIsLoading(true);
    }
  }, [length]);

  useEffect(() => {
    if (offset === 0 && isLoading) {
      window.scrollTo({
        top: 0,
      });
    }
  }, [offset, isLoading]);

  return (
    <InfiniteScrollLib
      next={fetchData}
      hasMore={hasMore}
      loader={
        <Spin
          size="large"
          style={{
            padding: '10px',
            width: '100%',
          }}
        />
      }
      dataLength={length}
    >
      {children}
    </InfiniteScrollLib>
  );
};
